import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Admin from "./app/components/Admin";
import LogIn from "./app/components/Admin/components/LogIn";
import Okta from "./app/components/Okta";
import Home from "./app/components/Home";
import App from "./App";
import { getWorlds } from "./app/services/worlds";
import { setUser, setWorlds } from "./app/redux/features/user";

export default function Router() {
  const dispatch = useDispatch();
  const userName = window.localStorage.getItem("userName");
  const userRole = window.localStorage.getItem("userRole");
  const { session, role } = useSelector((state) => state.user);
  let loaded = false;

  if (!session && userName) {
    getWorlds(userName).then((res) => {
      if (res.status === 200) {
        dispatch(setUser({ userName, role: parseInt(userRole) }));
        dispatch(setWorlds(res.data));
        loaded = true;
      }
    });
  } else {
    loaded = true;
  }

  return (
    loaded && (
      <BrowserRouter>
        <Routes>
          <Route
            path="/"
            element={(session && role === 1) || true ? <App /> : <Home />}
          />
          <Route
            path="/admin"
            element={session && role === 0 ? <Admin /> : <LogIn />}
          />
          <Route path="/admin/login" element={<LogIn />} />
          <Route path="/login" element={<Okta />} />
          <Route path="/home" element={<Home />} />
        </Routes>
      </BrowserRouter>
    )
  );
}
