import TreeNode from "../../../structures/TreeNode";
import random from "../../../utils/random";

/*
const pos0 = new TreeNode(
  0,
  false,
  -2345,
  1070,
  "No tengo ni idea de por dónde empezar para trámitar mi pensión.",
  0
);

const pos1 = new TreeNode(
  0,
  false,
  -2850,
  1370,
  "No tengo ni idea de por dónde empezar para trámitar mi pensión.",
  0
);

const pos2 = new TreeNode(
  0,
  false,
  -3415,
  1700,
  "No tengo ni idea de por dónde empezar para trámitar mi pensión.",
  0
);

const pos3 = new TreeNode(
  0,
  true,
  -3015,
  2185,
  "No tengo ni idea de por dónde empezar para trámitar mi pensión.",
  0
);

const pos4 = new TreeNode(
  0,
  true,
  -2765,
  2330,
  "No tengo ni idea de por dónde empezar para trámitar mi pensión.",
  0
);

const pos5 = new TreeNode(
  0,
  false,
  -2295,
  2610,
  "No tengo ni idea de por dónde empezar para trámitar mi pensión.",
  0
);

const pos6 = new TreeNode(
  0,
  true,
  -2665,
  2810,
  "No tengo ni idea de por dónde empezar para trámitar mi pensión.",
  0
);

const pos7 = new TreeNode(
  0,
  false,
  -3065,
  3080,
  "No tengo ni idea de por dónde empezar para trámitar mi pensión.",
  0
);

const pos8 = new TreeNode(
  0,
  true,
  -2840,
  3320,
  "No tengo ni idea de por dónde empezar para trámitar mi pensión.",
  0
);

const pos9 = new TreeNode(
  0,
  true,
  -2295,
  3460,
  "No tengo ni idea de por dónde empezar para trámitar mi pensión.",
  0
);

node1.quotes = [
  "“”",
  "“”",
  "“”",
];
*/

const node1 = new TreeNode(
  0,
  false,
  -2345,
  1070,
  "No tengo ni idea de por dónde empezar a tramitar mi pensión.",
  0
);

node1.quotes = [
  "“Deberían de informarle a todos su clientes con tiempo los pasos y lo que tienen que hacer, así sería todo más fácil.”",
  "“No tení ni idea por dónde empezar el trámite para obtener mi pensión, a veces me mareaban tanto que creí que nunca me la darían.”",
];

const node1_1_1 = new TreeNode(
  1,
  false,
  -2850,
  1370,
  "Hola estás llamando a Profuturo, ¿en qué podemos ayudarte?",
  0
);

node1_1_1.quotes = [
  "“La señorita me explicó muy bien que era lo que tenía que hacer, excelente asesoría por parte de su personal.”",
  "“La persona que me contestó no me dijo con certeza que era lo que tenía que hacer y no resolvió ninguna de mis dudas.”",
];

const node1_1_2 = new TreeNode(
  0,
  false,
  -2850,
  1370,
  "Hola, acabo de cumplir 65 años y me gustaría hacer el trámite para obtener mi pensión.",
  0
);

const node1_1_3 = new TreeNode(
  1,
  false,
  -2850,
  1370,
  "Claro, ¿en qué año empezaste a cotizar al IMSS?",
  0
);

const node1_1_4 = new TreeNode(
  0,
  false,
  -2850,
  1370,
  "No sé, ¿cómo puedo saberlo?",
  0
);

const node1_1_5 = new TreeNode(
  1,
  false,
  -2850,
  1370,
  "Revisa en tu último Estado de Cuenta, en la parte superior viene a qué ley perteneces.",
  0
);

/*
const node1_1_6 = new TreeNode(
  2,
  false,
  -2850,
  1370,
  "Juego 1 (Próximamente)",
  0
);
*/

const node1_1_7 = new TreeNode(
  0,
  false,
  -2850,
  1370,
  "Dice que pertenezco a la Ley 73, ¿esto qué siginifica?",
  0
);

const node1_1_8 = new TreeNode(
  1,
  false,
  -2850,
  1370,
  "Que empezaste a cotizar antes de julio de 1997.",
  0
);

const node1_1_9 = new TreeNode(
  0,
  false,
  -2850,
  1370,
  "Ok, y ¿eso me impacta? ¿qué tengo que hacer? ¿cuáles son los requisitos?",
  0
);

const node1_2_1 = new TreeNode(
  0,
  false,
  -3415,
  1700,
  "Ingresé a internet, y al parecer debo de pertenecer a una de las dos leyes. Sólo que no estoy segura a qué Ley pertenezco, ¿cómo podré saberlo?",
  0
);

node1_2_1.quotes = [
  "“En su página no viene nada de información.”",
  "“En su blog encontré una nota muy buena con todos los requisitos, pero, no me dice qué hacer ni nada, deberían de complementarla y compartir esa nota.”",
];

const node1_2_2 = new TreeNode(
  0,
  false,
  -3415,
  1700,
  "Al parecer hay una página del IMSS que te da una constancia y ahí dice la fecha en la que empecé a trabajar.",
  0
);

/*
const node1_2_3 = new TreeNode(
  0,
  false,
  -3415,
  1700,
  "Juego 1 (Próximamente)",
  0
);
*/

const node1_2_4 = new TreeNode(
  0,
  false,
  -3415,
  1700,
  "Listo, al parecer empecé a trabajar en 1994, por lo que me correspondé la Ley 73.",
  1
);

const node1_2_5 = new TreeNode(
  0,
  false,
  -3415,
  1700,
  "Pero, ¿qué tengo que hacer? Marcaré a Profuturo para que me den más información.",
  0
);

const node1_2_6_1 = new TreeNode(
  1,
  false,
  -2850,
  1370,
  "Hola estás llamando a Profuturo, ¿en qué podemos ayudarte?",
  0
);

const node1_2_6_2 = new TreeNode(
  0,
  false,
  -2850,
  1370,
  "Hola, acabo de cumplir 65 años y me gustaría hacer el trámite para obtener mi pensión.",
  0
);

const node1_2_6_3 = new TreeNode(
  1,
  false,
  -2850,
  1370,
  "Claro, ¿en qué año empezaste a cotizar al IMSS?",
  0
);

const node1_2_6_4 = new TreeNode(
  0,
  false,
  -2850,
  1370,
  "Empecé antes de 1997, por lo que entiendo que me aplica la Ley 73, pero me gustaría saber qué es lo que tengo que hacer y cuáles son los requisitos que debo de cumplir.",
  0
);

const node1_3_1 = new TreeNode(
  1,
  false,
  -2850,
  1370,
  "Los requisitos para pensionarse por Ley 73 son:\n- Debes de tener 65 años\n- Debes de haber dejado de cotizar ante el IMSS\n- Debes de tener por lo menos 500 semanas cotizadas\n-Debes de encontrarte dentro del período de conservación de derechos, que será igual a la cuarta parte del tiempo cubierto por tus cotizaciones semanales, contando a partir de la fecha de tu baja.",
  0
);

node1_3_1.quotes = [
  "“Te dicen que hacer, pero no ubican que es un tema desconocido y nos gustaría que nos acompañaran más.”",
];

const node1_3_2 = new TreeNode(
  0,
  false,
  -2850,
  1370,
  "De acuerdo a lo que me comentas, creo que tengo todo, sólo ¿cómo sé sí tengo las 500 semanas cotizadas?",
  0
);

const node1_3_3 = new TreeNode(
  1,
  false,
  -2850,
  1370,
  'Busca en internet "semanas cotizadas IMSS" y con tu CURP podrás consultarlas, lo podemos de hacer de una vez.',
  0
);

const node1_3_4 = new TreeNode(
  0,
  false,
  -2850,
  1370,
  "Perfecto, muchas gracias, ya estoy ingresando al IMSS.",
  0
);

const node1_4_1_1 = new TreeNode(
  0,
  false,
  -2850,
  1370,
  "Listo, al parecer tengo 458 semanas cotizadas, ¿esto qué significa?",
  0
);

node1_4_1_1.quotes = [
  "“Cómo no te avisan antes que necesitas tener un mínimo de semanas cotizadas, ni si quiera sabía que era eso, ahora tengo que ver cómo las consigo, pagando o regresando a trabajar”",
];

const node1_4_1_2 = new TreeNode(
  1,
  false,
  -2850,
  1370,
  "Significa que el IMSS emitirá una negativa de pensión y podrás retirar el saldo de tu Afore en una sóla exhibición.",
  0
);

const node1_4_1_3 = new TreeNode(
  0,
  false,
  -2850,
  1370,
  "Entonces, ¿no tendré pensión?",
  0
);

const node1_4_1_4 = new TreeNode(1, false, -2850, 1370, "Así es.", 0);

const node1_4_1_5 = new TreeNode(
  0,
  false,
  -2850,
  1370,
  "Está bien, muchas gracias.",
  0
);

const node1_4_2_0 = new TreeNode(
  2,
  false,
  -2850,
  1370,
  "Juego 1 (Próximamente)\nSumar Horas",
  0
);

node1_4_2_0.game = 10;

const node1_4_2_1 = new TreeNode(
  0,
  false,
  -2850,
  1370,
  "Listo, al parecer tengo 750 semanas cotizadas, entonces, ¿si cumplo con todos los requisitos?",
  0
);

node1_4_2_1.quotes = [
  "“Me llegó un mail con la información que necesitaba saber, lo que me aligeró más el trámite porque ya sabía si cumplía o no los requisitos”",
];

const node1_4_2_2 = new TreeNode(
  1,
  false,
  -2850,
  1370,
  "Así es, ahora lo que tenemos que hacer es verificar si tienes o no tu Expediente de Identificación, este es necesario para hacer el trámite de tu pensión.",
  0
);

const node1_4_2_3 = new TreeNode(
  0,
  false,
  -2850,
  1370,
  "Entiendo, ¿cómo sé si lo tengo?",
  0
);

const node1_4_2_4 = new TreeNode(
  1,
  false,
  -2850,
  1370,
  "Estoy verificando en nuestro sistemas, en un momento te confirmo.",
  0
);

const node1_4_2_5 = new TreeNode(
  2,
  false,
  -2850,
  1370,
  "Juego 2 (Próximamente)\nPCs",
  0
);

node1_4_2_5.game = 11;

const node1_5 = new TreeNode(0, false, -2850, 1370, "Final 1", 0);

node1_5.isLast = true;
node1_5.ending = 1;

const node1_6_1_1 = new TreeNode(
  1,
  false,
  -2850,
  1370,
  "Revisando el sistema veo que no tienes completo tu Expediente, por lo que tendrías que ir a sucursal a completarlo. Te agendaré una cita para el trámite.",
  0
);

node1_6_1_1.quotes = [
  "“Esto del Expedinete siento que sólo es hacer tiempo para complicarte y no darte tu dinero, ¿por qué no me lo mencionaron antes?”",
];

const node1_6_1_2 = new TreeNode(0, false, -2850, 1370, "Muchas gracias.", 1);

const node1_6_2_1 = new TreeNode(
  1,
  false,
  -2850,
  1370,
  "Listo, veo que ya tienes completo tu Expediente, por lo que tendrías que ir al IMSS a la subdelegación que te corresponde y decir que quieres hacer tu trámite de pensión. Deberás asistir con los siguientes documentos:\n1. Estado de Cuenta / Resumen de saldos\n2. Comprobante de la cuenta a la que le van a depositar su pensión\n3. Identificación oficial",
  0
);

const node1_6_2_2 = new TreeNode(
  0,
  false,
  -2850,
  1370,
  "Perfecto, muchas gracias.",
  1
);

const node2_1 = new TreeNode(
  1,
  true,
  -3015,
  2185,
  "Hola, bienvenido a Profuturo, ¿tienes cita agendada?",
  0
);

node2_1.dialogAnchor = { y: 2.1 };

node2_1.quotes = [
  "“En sucursal se tardaron años en atenderme, y además fui varias veces porque me lo rechazaban por un tema de firmas, no puedo creer las vueltas que nos hacen dar.”",
];

const node2_2 = new TreeNode(
  0,
  true,
  -3015,
  2185,
  "Hola, sí tengo cita para hacer mi Expediente de Identificación.",
  0
);

const node2_3 = new TreeNode(
  1,
  true,
  -3015,
  2185,
  "Aquí la tengo, por favor pasa con la asistente.",
  0
);

node2_3.dialogAnchor = { y: 2.1 };

const node3_1 = new TreeNode(
  0,
  true,
  -2765,
  2330,
  "Hola, hice cita para hacer mi Expediente de Identificación.",
  0
);

node1.quotes = [
  "“La señorita en sucursal fue muy amable, tuvo mucha paciencia al tomar mis huellas y me explicó cada parte del trámite.”",
];

const node3_2 = new TreeNode(
  1,
  true,
  -2765,
  2330,
  "Perfecto, ¿me podrías comparitr tus documentos?",
  0
);

node3_2.dialogAnchor = { y: 2.1 };

const node3_3 = new TreeNode(0, true, -2765, 2330, "Aquí están.", 0);

const node3_4 = new TreeNode(
  2,
  true,
  -2765,
  2330,
  "Dialogo del trámite del expediente y juego 3 (Próximamente)\nCURP",
  0
);

node3_4.game = 12;

///

const node3_4_1 = new TreeNode(
  1,
  true,
  -2765,
  2330,
  "Hay un problema con tu CURP, para arreglarlo tienes que ir a RENAPO, esto puede tardar hasta 70 días naturales en resolverse.",
  0
);

node3_4_1.dialogAnchor = { y: 2.1 };

node3_4_1.quotes = [
  "“Nunca me había comentado del Expdiente y ahora resulta que lo necesito para hacer el trámite, sólo nos ponen trabas para no sacar el dinero.”",
];

const node3_4_2 = new TreeNode(0, true, -2765, 2330, "Muchas gracias.", 0);

const node3_4_3 = new TreeNode(
  0,
  false,
  -2295,
  2610,
  "Ya fui a RENAPO para resolver este tema, me comentaron que me dan resolución en 70 días, me urge el dinero y ya se detuvo todo por esto, pero bueno esperaré.",
  0
);

const node3_4_4 = new TreeNode(
  2,
  false,
  -2295,
  2610,
  "70 días después.",
  70 * 24
);

const node3_4_5 = new TreeNode(
  0,
  false,
  -2295,
  2610,
  "Listo, ya quedó resuelto lo de mi CURP, iré mañana a la sucursal de Profuturo para retomar el trámite.",
  0
);

const node3_4_6 = new TreeNode(
  0,
  true,
  -3015,
  2185,
  "Hola, ya había empezado a hacer el trámite, pero tuve un problema con mi CURP, ya quedó resuelto y vengo a hacer mi trámite de Expediente.",
  24
);

const node3_4_7 = new TreeNode(
  1,
  true,
  -3015,
  2185,
  "Perfecto, por favor pase con el asistente para ser atendido.",
  0
);

node3_4_7.dialogAnchor = { y: 2.1 };

const node3_4_8 = new TreeNode(
  1,
  true,
  -2765,
  2330,
  "Buen día. Entiendo que viene a hacer su trámite de Expediente, ¿me puede compartir sus documentos por favor?",
  0
);

node3_4_8.dialogAnchor = { y: 2.1 };

const node3_4_9 = new TreeNode(0, true, -2765, 2330, "Aquí están", 0);

///

const node3_5 = new TreeNode(
  1,
  true,
  -2765,
  2330,
  "Listo, en 5 días hábiles te deberá de llegar una confirmación a tu correo de que ya quedó tu Expediente. Con esto ya podrás ir al IMSS para inciar el trámite.",
  0
);

node3_5.dialogAnchor = { y: 2.1 };

const node3_6 = new TreeNode(
  0,
  true,
  -2765,
  2330,
  "Perfecto, muchas gracias.",
  0
);

const node4_1 = new TreeNode(
  2,
  false,
  -2295,
  2610,
  "Pasan los 5 días.",
  24 * 5
);

node4_1.quotes = [
  "“No me llegó nunca una notificación, tuve que marcarles para saber el estetus de mi trámite.”",
];

const node4_2 = new TreeNode(
  0,
  false,
  -2295,
  2610,
  "Ya me llegó la confirmación de mi Expediente.",
  0
);

const node5_1_1 = new TreeNode(
  1,
  false,
  -2850,
  1370,
  "Hola estás llamando a Profuturo, ¿en qué podemos ayudarte?",
  0
);

const node5_1_2 = new TreeNode(
  0,
  false,
  -2850,
  1370,
  "Hola, me acaba de llegar la confirmación de que mi Expediente se registró con éxito, para tramitar mi pensión, ¿Cuál es el siguiente paso?",
  0
);

const node5_1_3 = new TreeNode(
  1,
  false,
  -2850,
  1370,
  "Como ya tienes completo tu Expediente, lo que tendrías hacer es ir al IMSS a la subdelegación que te corresponde y decir que quieres hacer tu trámite de pensión. Deberás asistir con los siguientes documentos:\n1. Estado de Cuenta / Resumen de saldos\n2. Comprobante de la cuenta a la que le van a depositar su pensión\n3. Identificación oficial",
  0
);

const node5_1_4 = new TreeNode(
  0,
  false,
  -2850,
  1370,
  "Perfecto, muchas gracias. ¿Me podrían compartir mi Estado de Cuenta o resumen de saldos?",
  0
);

const node5_1_5 = new TreeNode(
  1,
  false,
  -2850,
  1370,
  "Claro, se lo hacemos llegar por correo electrónico.",
  0
);

const node5_1_6 = new TreeNode(
  0,
  false,
  -2850,
  1370,
  "Perfecto, muchas gracias.",
  1
);

const node5_2_1 = new TreeNode(
  0,
  true,
  -2665,
  2810,
  "Hola, quisiera hacer el trámite para pensionarme.",
  0
);

node5_2_1.quotes = [
  "“Los trámites con el IMSS son tardados y confusos”",
  "“Me ayudó bastante que nen sucursal me dijeran exactamente lo que necesitaba y qué iba a pasar al momento de ir la IMSS para el trámite de pensión.”",
];

const node5_2_2 = new TreeNode(
  1,
  true,
  -2665,
  2810,
  "Claro, ¿verificaste que cumples con todos los requisitos?",
  0
);

node5_2_2.dialogAnchor = { y: 2.1 };

const node5_2_3 = new TreeNode(
  0,
  true,
  -2665,
  2810,
  "Sí lo verifiqué anteriormente con mi Afore.",
  0
);

const node5_2_4 = new TreeNode(
  1,
  true,
  -2665,
  2810,
  "Perfecto, compárteme tus documentos.",
  0
);

node5_2_4.dialogAnchor = { y: 2.1 };

const node5_2_5 = new TreeNode(
  0,
  true,
  -2665,
  2810,
  "No sabía que tenía que traer documentos, ¿me podría decir cuáles necesito?",
  0
);

const node5_2_6 = new TreeNode(
  1,
  true,
  -2665,
  2810,
  "Los documentos son necesarios para hacer el tramite, necesitas: Resumen de saldos o Estado de Cuenta de tu Afore, comprobante de la cuenta bancaria en la que se depositaría u pensión y una identificación oficial.",
  0
);

node5_2_6.dialogAnchor = { y: 2.1 };

const node5_2_7 = new TreeNode(
  0,
  true,
  -2665,
  2810,
  "Perfecto, los consigo y regreso pronto.",
  0
);

const node6_1 = new TreeNode(
  1,
  false,
  -2850,
  1370,
  "Hola estás llamando a Profuturo, ¿en qué podemos ayudarte?",
  0
);

const node6_2 = new TreeNode(
  0,
  false,
  -2850,
  1370,
  "Hola, ¿me podrían compartir mi Estado de Cuenta o el Resumen de saldos de mi cuenta de Afore? Lo necesito para pensionarme.",
  0
);

const node6_3 = new TreeNode(
  1,
  false,
  -2850,
  1370,
  "Claro, se lo hacemos llegar por correo electrónico.",
  0
);

const node6_4 = new TreeNode(0, false, -2850, 1370, "Gracias.", 1);

const node7_1 = new TreeNode(
  0,
  true,
  -2665,
  2810,
  "Hola, quisiera hacer el trámite para pensionarme, tengo todos los documentos listos.",
  0
);

const node7_2 = new TreeNode(
  1,
  true,
  -2665,
  2810,
  "Claro, ¿verificaste que cumples con todos los requisitos?",
  0
);

node7_2.dialogAnchor = { y: 2.1 };

const node7_3 = new TreeNode(
  0,
  true,
  -2665,
  2810,
  "Sí lo verifiqué anteriormente con mi Afore.",
  0
);

const node7_4 = new TreeNode(
  1,
  true,
  -2665,
  2810,
  "Perfecto, compárteme tus documentos.",
  0
);

node7_4.dialogAnchor = { y: 2.1 };

const node7_5 = new TreeNode(0, true, -2665, 2810, "Aquí están.", 0);

const node7_5_1 = new TreeNode(
  0,
  true,
  -2665,
  2810,
  "Juego 4 (Próximamente)\nDocumentos",
  0
);

node7_5_1.game = 2;

const node7_6 = new TreeNode(
  1,
  true,
  -2665,
  2810,
  "Veo que todo está en orden, revisaremos tu documentación a detalle y a más tadar en 30 días te estaremos buscando para que vengas por tu resolución de pensión.",
  0
);

node7_6.dialogAnchor = { y: 2.1 };

const node7_7 = new TreeNode(
  0,
  true,
  -2665,
  2810,
  "Muchas gracias, estaré pendiente.",
  1
);

const node8_1 = new TreeNode(
  0,
  false,
  -2295,
  2610,
  "No puedo creer que todavía tengo que esperar 30 días, no tengo ningún ingreso, ¿cómo esperan que esté tranquila?",
  0
);

node8_1.quotes = [
  "“Es difícil e incómodo quedarte más de un mes sin ingreso, deberían de ser más empáticos y darnos algún adelanto.”",
];

const node8_2 = new TreeNode(
  2,
  false,
  -2295,
  2610,
  "Después de 30 días.",
  30 * 24
);

const node8_3 = new TreeNode(
  0,
  false,
  -2295,
  2610,
  "Ya me llegó la notificación de que ya tienen lista mi resolución de pensión, ¡por fin!",
  0
);

node8_3.quotes = [
  "“Es difícil e incómodo quedarte más de un mes sin ingreso, deberían de ser más empáticos y darnos algún adelanto.”",
];

const node9_1 = new TreeNode(
  0,
  true,
  -2665,
  2810,
  "Hola, me llegó un mensaje diciéndome que ya está lista mi resolución de pensión.",
  0
);

const node9_2 = new TreeNode(1, true, -2665, 2810, "Claro, aquí está.", 0);

node9_2.dialogAnchor = { y: 2.1 };

const node9_3 = new TreeNode(
  1,
  false,
  -2665,
  2810,
  "Muy bien, entonces si está de acuerdo solo necesitaría tu firma y estaía todo listo.",
  0
);

const node9_3_1 = new TreeNode(
  0,
  false,
  -2665,
  2810,
  "Sí claro, está muy bien.",
  0
);

const node9_4 = new TreeNode(
  1,
  true,
  -2665,
  2810,
  "Perfecto, tu fecha de pago será en 15 días. Adicional, para retirar el ahorro que tienes en tu cuenta de Afore, tendrás que tramitarlo directamente con tu Afore después de 10 días hábiles.",
  0
);

node9_4.dialogAnchor = { y: 2.1 };

const node9_5 = new TreeNode(
  0,
  true,
  -2665,
  2810,
  "Perfecto, muchas gracias.",
  1
);

const node9_6 = new TreeNode(
  0,
  false,
  -2295,
  2610,
  "Agendaré cita de una vez para ir con Profuturo.",
  0
);

const node10_1 = new TreeNode(
  1,
  false,
  -3065,
  3080,
  "Hola, bienvenido a Profuturo, ¿tienes cita agendada?",
  10 * 24
);

node10_1.dialogAnchor = { y: 2.1 };

const node10_2 = new TreeNode(
  0,
  false,
  -3065,
  3080,
  "Hola, sí tengo cita para hacer el trámite de Retiro Total.",
  0
);

const node10_3 = new TreeNode(
  1,
  false,
  -3065,
  3080,
  "Aquí la tengo, por favor pasa con el asistente.",
  0
);

node10_3.dialogAnchor = { y: 2.1 };

/**
 * 
  -2840,
  3320,
 */

const node11_1 = new TreeNode(
  0,
  true,
  -2840,
  3320,
  "Hola, hice cita para hacer mi Retiro Total.",
  0
);

node11_1.quotes = [
  "“Al hacer el trámite de retiro, entendí que además de mi pensión tenía un ahorro aparte, nunca lo había entendido”",
];

const node11_2 = new TreeNode(
  1,
  true,
  -2840,
  3320,
  "Perfecto, ¿me podrías comparitr tus documentos?",
  0
);

node11_2.dialogAnchor = { x: 10, y: 2.2 };

const node11_3 = new TreeNode(0, true, -2840, 3320, "Aquí están.", 0);

const node11_4 = new TreeNode(
  1,
  true,
  -2840,
  3320,
  "Vamos a llenar la solicitud.",
  0
);

node11_4.dialogAnchor = { x: 10, y: 2.2 };

const node11_5 = new TreeNode(
  1,
  true,
  -2840,
  3320,
  "Listo, se mandará tu solicitud y en 5 días hábiles deberás de estar recibiendo una notificación con la conclusión de tu trámite.",
  0
);

node11_5.dialogAnchor = { x: 10, y: 2.2 };

const node11_6 = new TreeNode(
  0,
  true,
  -2840,
  3320,
  "Perfecto, muchas gracias",
  1
);

const node12 = new TreeNode(2, false, -2295, 3460, "Pasan 5 días hábiles", 0);

node12.quotes = [
  "“Porfuturo me ayudó con el trámite y todo quedó resuelto súper rápido.”",
];

const node12_1 = new TreeNode(
  0,
  false,
  -2295,
  3460,
  "Ya me llegó la confirmación del depósito de mi dinero. Me tardé más de un mes en hacer el trámite pero se logró.",
  5 * 24
);

const node12_2_1 = new TreeNode(
  0,
  false,
  -2295,
  3460,
  "Me llegó una notificación de que hubo un problema con mi información tendré que ir a sucursal nuevamente.",
  5 * 24
);

const node12_2_2 = new TreeNode(
  2,
  false,
  -2295,
  3460,
  "Cliente va a sucursal y arrgla el tema.",
  0
);

const node12_2_3 = new TreeNode(
  0,
  false,
  -2295,
  3460,
  "Listo, esperaré nuevamente la notificación a los 5 días hábiales, confío en que todo estará bien.",
  0
);

const node13 = new TreeNode(0, false, -2295, 3460, "Final", 0);

node13.isLast = true;
node13.ending = 0;

node1.descendants = [
  {
    node: node1_1_1,
    hint: "Marcaré a mi Afore Profuturo para que me den información",
  },
  {
    node: node1_2_1,
    hint: "Voy a investigar en internet",
  },
];

node1_1_1.descendants = [
  {
    node: node1_1_2,
    hint: "Continuar",
  },
];

node1_1_2.descendants = [
  {
    node: node1_1_3,
    hint: "Continuar",
  },
];

node1_1_3.descendants = [
  {
    node: node1_1_4,
    hint: "Continuar",
  },
];

node1_1_4.descendants = [
  {
    node: node1_1_5,
    hint: "Continuar",
  },
];

node1_1_5.descendants = [
  {
    node: node1_1_7,
    hint: "Continuar",
  },
];

/*
node1_1_6.descendants = [
  {
    node: node1_1_7,
    hint: "Continuar",
  },
];
*/

node1_1_7.descendants = [
  {
    node: node1_1_8,
    hint: "Continuar",
  },
];

node1_1_8.descendants = [
  {
    node: node1_1_9,
    hint: "Continuar",
  },
];

node1_1_9.descendants = [
  {
    node: node1_3_1,
    hint: "Continuar",
  },
];

node1_2_1.descendants = [
  {
    node: node1_2_2,
    hint: "Continuar",
  },
];

node1_2_2.descendants = [
  {
    node: node1_2_4,
    hint: "Continuar",
  },
];

/*
node1_2_3.descendants = [
  {
    node: node1_2_4,
    hint: "Continuar",
  },
];
*/

node1_2_4.descendants = [
  {
    node: node1_2_5,
    hint: "Continuar",
  },
];

node1_2_5.descendants = [
  {
    node: node1_2_6_1,
    hint: "Continuar",
  },
];

node1_2_6_1.descendants = [
  {
    node: node1_2_6_2,
    hint: "Continuar",
  },
];

node1_2_6_2.descendants = [
  {
    node: node1_2_6_3,
    hint: "Continuar",
  },
];

node1_2_6_3.descendants = [
  {
    node: node1_2_6_4,
    hint: "Continuar",
  },
];

node1_2_6_4.descendants = [
  {
    node: node1_3_1,
    hint: "Continuar",
  },
];

node1_3_1.descendants = [
  {
    node: node1_3_2,
    hint: "Continuar",
  },
];

node1_3_2.descendants = [
  {
    node: node1_3_3,
    hint: "Continuar",
  },
];

node1_3_3.descendants = [
  {
    node: node1_3_4,
    hint: "Continuar",
  },
];

node1_3_4.descendants = [
  {
    node: random([
      node1_4_1_1,
      node1_4_2_0,
      node1_4_2_0,
      node1_4_2_0,
      node1_4_2_0,
      node1_4_2_0,
      node1_4_2_0,
      node1_4_2_0,
      node1_4_2_0,
      node1_4_2_0,
      node1_4_2_0,
      node1_4_2_0,
      node1_4_2_0,
      node1_4_2_0,
      node1_4_2_0,
      node1_4_2_0,
      node1_4_2_0,
      node1_4_2_0,
      node1_4_2_0,
      node1_4_2_0,
    ]),
    hint: "Continuar",
  },
];

node1_4_1_1.descendants = [
  {
    node: node1_4_1_2,
    hint: "Continuar",
  },
];

node1_4_1_2.descendants = [
  {
    node: node1_4_1_3,
    hint: "Continuar",
  },
];

node1_4_1_3.descendants = [
  {
    node: node1_4_1_4,
    hint: "Continuar",
  },
];

node1_4_1_4.descendants = [
  {
    node: node1_4_1_5,
    hint: "Continuar",
  },
];

node1_4_1_5.descendants = [
  {
    node: node1_5,
    hint: "Continuar",
  },
];

node1_4_2_0.descendants = [
  {
    node: node1_4_2_1,
    hint: "Continuar",
  },
  {
    node: node1_4_1_1,
    hint: "Continuar",
  },
];

node1_4_2_1.descendants = [
  {
    node: node1_4_2_2,
    hint: "Continuar",
  },
];

node1_4_2_2.descendants = [
  {
    node: node1_4_2_3,
    hint: "Continuar",
  },
];

node1_4_2_3.descendants = [
  {
    node: node1_4_2_4,
    hint: "Continuar",
  },
];

node1_4_2_4.descendants = [
  {
    node: node1_4_2_5,
    hint: "Continuar",
  },
];

node1_4_2_5.descendants = [
  {
    node: node1_6_1_1,
    hint: "No tiene el expediente",
  },
  {
    node: node1_6_2_1,
    hint: "Tiene el expediente",
  },
];

node1_6_1_1.descendants = [
  {
    node: node1_6_1_2,
    hint: "Continuar",
  },
];

node1_6_1_2.descendants = [
  {
    node: node2_1,
    hint: "Continuar",
  },
];

node1_6_2_1.descendants = [
  {
    node: node1_6_2_2,
    hint: "Continuar",
  },
];

node1_6_2_2.descendants = [
  {
    node: node7_1,
    hint: "Continuar",
  },
];

node2_1.descendants = [
  {
    node: node2_2,
    hint: "Continuar",
  },
];

node2_2.descendants = [
  {
    node: node2_3,
    hint: "Continuar",
  },
];

node2_3.descendants = [
  {
    node: node3_1,
    hint: "Continuar",
  },
];

node3_1.descendants = [
  {
    node: node3_2,
    hint: "Continuar",
  },
];

node3_2.descendants = [
  {
    node: node3_3,
    hint: "Continuar",
  },
];

node3_3.descendants = [
  {
    node: node3_4,
    hint: "Continuar",
  },
];

node3_4.descendants = [
  {
    node: node3_4_1,
    hint: "Continuar",
  },
  {
    node: node3_5,
    hint: "Continuar",
  },
];

node3_4_1.descendants = [
  {
    node: node3_4_2,
    hint: "Continuar",
  },
];

node3_4_2.descendants = [
  {
    node: node3_4_3,
    hint: "Continuar",
  },
];

node3_4_3.descendants = [
  {
    node: node3_4_4,
    hint: "Continuar",
  },
];

node3_4_4.descendants = [
  {
    node: node3_4_5,
    hint: "Continuar",
  },
];

node3_4_5.descendants = [
  {
    node: node3_4_6,
    hint: "Continuar",
  },
];

node3_4_6.descendants = [
  {
    node: node3_4_7,
    hint: "Continuar",
  },
];

node3_4_7.descendants = [
  {
    node: node3_4_8,
    hint: "Continuar",
  },
];

node3_4_8.descendants = [
  {
    node: node3_4_9,
    hint: "Continuar",
  },
];

node3_4_9.descendants = [
  {
    node: node3_5,
    hint: "Continuar",
  },
];

node3_5.descendants = [
  {
    node: node3_6,
    hint: "Continuar",
  },
];

node3_6.descendants = [
  {
    node: node4_1,
    hint: "Continuar",
  },
];

node4_1.descendants = [
  {
    node: node4_2,
    hint: "Continuar",
  },
];

node4_2.descendants = [
  {
    node: node5_1_1,
    hint: "Marcaré al Profuturo para ver cuáles son los siguientes pasos",
  },
  {
    node: node5_2_1,
    hint: "Iré al IMSS para tramitar mi Expediente",
  },
];

node5_1_1.descendants = [
  {
    node: node5_1_2,
    hint: "Continuar",
  },
];

node5_1_2.descendants = [
  {
    node: node5_1_3,
    hint: "Continuar",
  },
];

node5_1_3.descendants = [
  {
    node: node5_1_4,
    hint: "Continuar",
  },
];

node5_1_4.descendants = [
  {
    node: node5_1_5,
    hint: "Continuar",
  },
];

node5_1_5.descendants = [
  {
    node: node5_1_6,
    hint: "Continuar",
  },
];

node5_1_6.descendants = [
  {
    node: node7_1,
    hint: "Continuar",
  },
];

node5_2_1.descendants = [
  {
    node: node5_2_2,
    hint: "Continuar",
  },
];

node5_2_2.descendants = [
  {
    node: node5_2_3,
    hint: "Continuar",
  },
];

node5_2_3.descendants = [
  {
    node: node5_2_4,
    hint: "Continuar",
  },
];

node5_2_4.descendants = [
  {
    node: node5_2_5,
    hint: "Continuar",
  },
];

node5_2_5.descendants = [
  {
    node: node5_2_6,
    hint: "Continuar",
  },
];

node5_2_6.descendants = [
  {
    node: node5_2_7,
    hint: "Continuar",
  },
];

node5_2_7.descendants = [
  {
    node: node6_1,
    hint: "Continuar",
  },
];

node6_1.descendants = [
  {
    node: node6_2,
    hint: "Continuar",
  },
];

node6_2.descendants = [
  {
    node: node6_3,
    hint: "Continuar",
  },
];

node6_3.descendants = [
  {
    node: node6_4,
    hint: "Continuar",
  },
];

node6_4.descendants = [
  {
    node: node7_1,
    hint: "Continuar",
  },
];

node7_1.descendants = [
  {
    node: node7_2,
    hint: "Continuar",
  },
];

node7_2.descendants = [
  {
    node: node7_3,
    hint: "Continuar",
  },
];

node7_3.descendants = [
  {
    node: node7_4,
    hint: "Continuar",
  },
];

node7_4.descendants = [
  {
    node: node7_5,
    hint: "Continuar",
  },
];

node7_5.descendants = [
  {
    node: node7_6,
    hint: "Continuar",
  },
];

node7_5_1.descendants = [
  {
    node: node7_6,
    hint: "Continuar",
  },
];

node7_6.descendants = [
  {
    node: node7_7,
    hint: "Continuar",
  },
];

node7_7.descendants = [
  {
    node: node8_1,
    hint: "Continuar",
  },
];

node8_1.descendants = [
  {
    node: node8_2,
    hint: "Continuar",
  },
];

node8_2.descendants = [
  {
    node: node8_3,
    hint: "Continuar",
  },
];

node8_3.descendants = [
  {
    node: node9_1,
    hint: "Continuar",
  },
];

node9_1.descendants = [
  {
    node: node9_2,
    hint: "Continuar",
  },
];

node9_2.descendants = [
  {
    node: node9_3,
    hint: "Continuar",
  },
];

node9_3.descendants = [
  {
    node: node9_3_1,
    hint: "Continuar",
  },
];

node9_3_1.descendants = [
  {
    node: node9_4,
    hint: "Continuar",
  },
];

node9_4.descendants = [
  {
    node: node9_5,
    hint: "Continuar",
  },
];

node9_5.descendants = [
  {
    node: node9_6,
    hint: "Continuar",
  },
];

node9_6.descendants = [
  {
    node: node10_1,
    hint: "Continuar",
  },
];

node10_1.descendants = [
  {
    node: node10_2,
    hint: "Continuar",
  },
];

node10_2.descendants = [
  {
    node: node10_3,
    hint: "Continuar",
  },
];

node10_3.descendants = [
  {
    node: node11_1,
    hint: "Continuar",
  },
];

node11_1.descendants = [
  {
    node: node11_2,
    hint: "Continuar",
  },
];

node11_2.descendants = [
  {
    node: node11_3,
    hint: "Continuar",
  },
];

node11_3.descendants = [
  {
    node: node11_4,
    hint: "Continuar",
  },
];

node11_4.descendants = [
  {
    node: node11_5,
    hint: "Continuar",
  },
];

node11_5.descendants = [
  {
    node: node11_6,
    hint: "Continuar",
  },
];

node11_6.descendants = [
  {
    node: node12,
    hint: "Continuar",
  },
];

node11_6.descendants = [
  {
    node: random([node12_1, node12_1, node12_2_1]),
    hint: "Continuar",
  },
];

node12_1.descendants = [
  {
    node: node13,
    hint: "Continuar",
  },
];

node12_2_1.descendants = [
  {
    node: node12_2_2,
    hint: "Continuar",
  },
];

node12_2_2.descendants = [
  {
    node: node12_2_3,
    hint: "Continuar",
  },
];

node12_2_3.descendants = [
  {
    node: node12_1,
    hint: "Continuar",
  },
];

export default node1;
