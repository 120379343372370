import { Box, Button, LinearProgress, TextField } from "@mui/material";
import React, { useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { setUser } from "../../../redux/features/user";
import { adminLogIn } from "../../../services/users";

export default function Okta() {
  const [userName, setUserName] = useState("");
  const [password, setPassword] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleLogIn = () => {
    setIsLoading(true);
    adminLogIn(userName, password).then((res) => {
      setIsLoading(false);
      if (res.status === 200 && res.data.User.AdminAccess) {
        dispatch(setUser({ userName, role: res.data.User.UserName.role }));
        window.localStorage.setItem("userName", userName);
        window.localStorage.setItem("userRole", res.data.User.UserName.role);
        navigate("/admin");
      } else {
        setError(
          res.data.User
            ? "El usuario o la contraseña son incorrectos."
            : "Error desconocido. Vuelve a intentarlo."
        );
      }
    });
  };

  const handleUser = (e) => {
    setUserName(e.target.value);
  };

  const handlePassword = (e) => {
    setPassword(e.target.value);
  };

  return (
    <div id="admin-login">
      <div>
        <div className="txt">Iniciar como administrador</div>
        <TextField
          id="outlined-basic"
          label="Usuario"
          variant="outlined"
          value={userName}
          onChange={handleUser}
        />
        <TextField
          id="outlined-basic"
          label="Contraseña"
          variant="outlined"
          type="password"
          value={password}
          onChange={handlePassword}
        />
        <div className="error" style={{ opacity: error ? 1 : 0 }}>
          {error || ""}
        </div>
        <Button variant="contained" onClick={handleLogIn}>
          Iniciar sesión
        </Button>
        {isLoading && (
          <div className="loading">
            <Box sx={{ width: "100%" }}>
              <LinearProgress />
            </Box>
          </div>
        )}
      </div>
    </div>
  );
}
