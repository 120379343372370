import { memo, useState } from "react";

import INEGame from "./components/INE";
import GameDialog from "../../baseComponents/GameContainer/components/GameDialog";

import successPhase1Message from "../../assets/games/ValidarIdentidad/success_phase1.png";
import successMessage from "../../assets/games/ValidarIdentidad/success.png";
import errorMessage from "../../assets/games/ValidarIdentidad/error.png";
import message from "../../assets/games/ValidarIdentidad/message.png";
import PhotoGame from "./components/Photo";

const Index = memo(({ visible, close, reset, pension }) => {
  const [phase, setPhase] = useState("INE");
  const [starGame, setStarGame] = useState(false);
  const [displayMessagePhase1, setDisplayMessagePhase1] = useState(false);
  const [success, setSuccess] = useState(null);
  const [resetGame, setResetGame] = useState(false);
  const [displayMessage, setDisplayMessage] = useState(false);

  if (!visible) return null;

  if (displayMessage) {
    return (
      <GameDialog
        image={message}
        button="Continuar"
        close={() => {
          close();
        }}
        scale={1}
        alpha={1}
      />
    );
  }

  if (displayMessagePhase1) {
    return (
      <GameDialog
        image={successPhase1Message}
        button="Continuar"
        close={() => {
          setPhase("Photo");
          setDisplayMessagePhase1(false);
          setStarGame(true);
          setResetGame(true);
        }}
        scale={1}
        alpha={1}
      />
    );
  }

  if (success === false) {
    return (
      <GameDialog
        image={errorMessage}
        button="Reintentar"
        close={() => {
          setSuccess(null);
          setResetGame(true);
        }}
        scale={1}
        alpha={1}
      />
    );
  }

  if (success === true) {
    return (
      <GameDialog
        image={successMessage}
        button="Continuare"
        close={() => {
          setSuccess(null);
          setDisplayMessage(true);
        }}
        scale={1}
        alpha={1}
      />
    );
  }

  return phase === "INE" ? (
    <INEGame
      reset={resetGame}
      onEndGame={(success) => {
        if (success) {
          setDisplayMessagePhase1(true);
        } else {
          setSuccess(success);
        }
      }}
    />
  ) : (
    <PhotoGame
      startGame={starGame}
      reset={resetGame}
      onEndGame={(success) => {
        setSuccess(success);
      }}
    />
  );
});

export default Index;
