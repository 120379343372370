import axios from "./instance";

export const getPlayers = (username) => {
  return axios({
    url: `/users/players`,
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
    },
    data: {
      username,
    },
  }).then((res) => res);
};

export const getComments = (username) => {
  return axios({
    url: `/users/comments`,
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
    },
    data: {
      username,
    },
  }).then((res) => res);
};

export const oktaLogIn = (username, password) => {
  return axios({
    url: `/users/aut`,
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    data: {
      username,
      password,
    },
  }).then((res) => res);
};

export const adminLogIn = (username, password) => {
  return axios({
    url: `/users/autadmin`,
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    data: {
      username,
      password,
    },
  }).then((res) => res);
};

export const addComment = (username, comment, worldType) => {
  return axios({
    url: `/users/comment`,
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    data: {
      username,
      comment,
      worldType,
    },
  }).then((res) => res);
};
