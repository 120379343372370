import { useEffect, useState } from "react";
import Lottie from "lottie-web";
import * as PIXI from "pixi.js";
import { useApp } from "@inlet/react-pixi/animated";
import useWindowSize from "./useWindowSize";

export default function LottiePixiLoader(
  bounds = [],
  lotties = [],
  scale = window.innerWidth / 1536
) {
  const windowSize = useWindowSize();
  const app = useApp();
  const [frames, setFrames] = useState([]);
  const [SVGframes, setSVGFrames] = useState([]);
  let width = bounds;
  let height = bounds;
  let animations = [];

  for (let i = 0; i < lotties.length; i++) {
    animations.push(
      Lottie.loadAnimation({
        container: document.createElement("div"),
        renderer: "svg",
        loop: false,
        autoplay: false,
        animationData: lotties[i],
      })
    );
  }

  useEffect(() => {
    if (lotties.length) {
      makeFrames();
    }
    /*
    dispatch(setTransition(false));

    setTimeout(() => {
      setShowTitle(1);
    }, 1000);
    */
    return () => {
      //dispatch(resetNode());
      //dispatch(resetTime());
    };
  }, []);

  useEffect(() => {
    console.log("progress", app.loader.progress);
  }, [app.loader.progress]);

  useEffect(async () => {
    if (!!SVGframes.length) {
      app.loader.reset();
      //let w = Math.round(width[SVGframes.length] * scale);
      //let h = Math.round(height[SVGframes.length] * scale);

      let a = [];
      let toLoad = [];

      for (let j = 0; j < SVGframes.length; j++) {
        for (let i = 0; i < SVGframes[j].length; i++) {
          SVGframes[j][i].setAttribute("width", Math.round(width[j] * scale));
          SVGframes[j][i].setAttribute("height", Math.round(width[j] * scale));

          /*
          a[i] = document.createElement("div");

          a[i].innerHTML += new XMLSerializer().serializeToString(
            SVGframes[j][i].cloneNode(true)
          );

          a[i].firstElementChild.setAttribute("width", width);
          a[i].firstElementChild.setAttribute("height", height);

          console.log(a[i]);
          console.log(await htmlToImage.toBlob(a[i]));
          */

          toLoad.push({
            key: `frame${j}${i}`,
            name: `frame${j}${i}`,
            url:
              "data:image/svg+xml;base64," +
              window.btoa(
                new XMLSerializer().serializeToString(
                  SVGframes[j][i].cloneNode(true)
                )
              ),
          });
        }
      }

      app.loader.add(toLoad);

      if (SVGframes.length === animations.length) {
        app.loader.load(() => {
          //let w = Math.round(width / 5);
          //let h = Math.round(height);
          //, new PIXI.Rectangle(w, 0, w, h)

          let sheets = [];

          let currentFrames = [];
          for (let j = 0; j < SVGframes.length; j++) {
            sheets = [];
            for (let i = 0; i < SVGframes[j].length; i++) {
              sheets.push(
                new PIXI.Texture(
                  new PIXI.BaseTexture.from(
                    app.loader.resources[`frame${j}${i}`].url
                  )
                )
              );
            }
            currentFrames.push([...sheets]);
          }

          setFrames([...currentFrames]);
          return () => {
            //dispatch(resetNode());
            //dispatch(resetTime());
          };
        });
      }
    }
    return () => {
      app.loader.reset();
      //dispatch(resetNode());
    };
  }, [SVGframes, windowSize]);

  useEffect(() => {
    if (SVGframes.length < animations.length) {
      makeFrames();
    }
  }, [SVGframes]);

  const makeFrames = (i = 0, images = []) => {
    if (i < animations[SVGframes.length].totalFrames) {
      animations[SVGframes.length].goToAndStop(i, true);
      let svgNode =
        animations[SVGframes.length].wrapper.firstElementChild.cloneNode(true);
      svgNode.setAttribute("width", width[SVGframes.length]);
      svgNode.setAttribute("height", height[SVGframes.length]);
      images.push(svgNode);
      makeFrames(i + 1, images);
    } else {
      //console.log("animation created", play.isDescendant);
      let SVGs = SVGframes;
      SVGs.push(images);
      setSVGFrames([...SVGs]);
      //dispatch(setTransition(false));
    }
  };

  // console.log("ReactPixiLoader");

  return frames;
}
