import { Container, Sprite } from "@inlet/react-pixi";
import { memo, useEffect, useState } from "react";

import INE from "../../../assets/games/ValidarIdentidad/ine.png";
import INEContainer from "../../../assets/games/ValidarIdentidad/rectangle_ine.png";

const items = new Array(28).fill(0);

const INEGame = memo(({ reset, onEndGame = () => null }) => {
  const [itemPosition, setItemPosition] = useState(-250 * items.length);
  const [stop, setStop] = useState(false);

  useEffect(() => {
    const interval = setInterval(() => {
      setItemPosition((prev) => prev + 1);
    }, 10);

    if (stop) {
      clearInterval(interval);
      return;
    }

    if (itemPosition > 250) {
      onEndGame(false);
      clearInterval(interval);
    }
    return () => clearInterval(interval);
  }, [itemPosition, stop, onEndGame]);

  useEffect(() => {
    setItemPosition(-250 * items.length);
    setStop(false);
  }, [reset]);

  return (
    <Container
      position={{
        x: window.innerWidth / 2 - 450,
        y: window.innerHeight / 2 - 150,
      }}
    >
      <Container
        position={{
          x: 350,
          y: window.innerHeight / 4 - 150,
        }}
      >
        <Sprite image={INEContainer} interactive height={100} width={150} />
      </Container>
      <Container position={{ x: 0, y: 0 }}>
        {items.map((_, i) => (
          <Container
            interactive
            key={i}
            pointerdown={() => {
              const center = window.innerHeight / 4 - 150;
              const position = i * 250 + itemPosition;
              const isSuccess =
                position > center - 50 && position < center + 50;
              setStop(true);
              onEndGame(isSuccess);
              console.log(isSuccess ? "correcto :D" : "incorrecto");
            }}
            position={[350, i * 250 + itemPosition]}
          >
            <Sprite interactive image={INE} height={100} width={150} />
          </Container>
        ))}
      </Container>
    </Container>
  );
});

export default INEGame;

/*
useEffect(() => {
    let timeout = null;

    if (visible) {
      timeout = setTimeout(close, 1500);
    }

    return () => clearTimeout(timeout);
  }, [visible, close]);
*/
