import { createSlice } from "@reduxjs/toolkit";

export const infoSlice = createSlice({
  name: "info",
  initialState: {
    info: false,
  },
  reducers: {
    setInfo: (state, action) => {
      state.info = action.payload;
    },
  },
});

export const { setInfo } = infoSlice.actions;

export default infoSlice.reducer;
