import { createSlice } from "@reduxjs/toolkit";

export const dataUpdateTimeSlice = createSlice({
  name: "dataUpdateTime",
  initialState: {
    timeCount: 0,
  },
  reducers: {
    addTime: (state, action) => {
      state.timeCount += action.payload;
    },
    resetTime: (state) => {
      state.timeCount = 0;
    },
  },
});

export const { addTime, resetTime } = dataUpdateTimeSlice.actions;

export default dataUpdateTimeSlice.reducer;
