import { createSlice } from "@reduxjs/toolkit";

export const generalSlice = createSlice({
  name: "general",
  initialState: {
    section: "",
    comment: "",
    commentPos: 0,
    commentError: "",
  },
  reducers: {
    setSection: (state, action) => {
      state.section = action.payload;
    },
    resetComment: (state) => {
      state.comment = "";
    },
    setComment: (state, action) => {
      state.comment = action.payload;
    },
    setCommentPos: (state, action) => {
      state.commentPos = action.payload;
    },
    setCommentError: (state, action) => {
      state.commentError = action.payload;
    },
  },
});

export const {
  setSection,
  setComment,
  resetComment,
  setCommentPos,
  setCommentError,
} = generalSlice.actions;

export default generalSlice.reducer;
