import { Container, Graphics, Sprite } from "@inlet/react-pixi/animated";
import React, { useCallback, useEffect, useRef, useState } from "react";
import ProgressBar from "../../baseComponents/ProgressBar";
import rasterizeSVG from "../../utils/rasterizeSVG";

import phone from "../../assets/games/clientInfoGame/phone";
import profuturo from "../../assets/games/clientInfoGame/profuturo";
import avatar from "../../assets/games/clientInfoGame/photo";
import name from "../../assets/games/clientInfoGame/name";
import text from "../../assets/games/clientInfoGame/text";
import date from "../../assets/games/clientInfoGame/date";
import sign from "../../assets/games/clientInfoGame/sign";
import fingerprint from "../../assets/games/clientInfoGame/fingerprint";

import GameDialog from "../../baseComponents/GameContainer/components/GameDialog";
import gameOk from "../../assets/games/clientInfoGame/juego2_ganar.png";
import gameWrong from "../../assets/games/clientInfoGame/juego2_perder.png";

export default function index({ visible, close, reset }) {
  const Animated = () => {
    const scale = window.innerWidth / 1536;
    const [message, setMessage] = useState({ status: false, visible: false });
    const [currentTime, setCurrentTime] = useState(0);
    const [selected, setSelected] = useState(-1);
    const [mousePosition, setMousePosition] = useState([0, 0]);
    const TIME_LIMIT = 20;
    const placed = useRef(0);
    const tries = useRef(0);

    const [draggables, setDraggables] = useState([
      {
        initPos: [-400, -200],
        finalPos: [-64, -176],
        state: false,
        isDragging: false,
      },
      {
        initPos: [400, 150],
        finalPos: [15, -88],
        state: false,
        isDragging: false,
      },
      {
        initPos: [-300, -130],
        finalPos: [4, -32],
        state: false,
        isDragging: false,
      },
      {
        initPos: [-270, 0],
        finalPos: [4, 45],
        state: false,
        isDragging: false,
      },
      {
        initPos: [270, 0],
        finalPos: [-45, 120],
        state: false,
        isDragging: false,
      },
      {
        initPos: [350, -200],
        finalPos: [52, 120],
        state: false,
        isDragging: false,
      },
      {
        initPos: [-400, 150],
        finalPos: [5, 165],
        state: false,
        isDragging: false,
      },
    ]);

    const container = useCallback((g) => {
      g.clear();

      g.beginFill(0xffffff, 0.00000001);
      g.drawRect(
        -window.innerWidth / 2 / scale,
        -window.innerHeight / 2 / scale,
        window.innerWidth / scale,
        window.innerHeight / scale
      );
      g.endFill();
    });

    const profuturoShadow = useCallback((g) => {
      g.clear();

      g.beginFill(0xffffff, 0.00000001);
      g.drawRect(-100, -200, 70, 50);
      g.endFill();
    });

    const avatarShadow = useCallback((g) => {
      g.clear();

      g.beginFill(0xffffff, 0.00000001);
      g.drawCircle(4, -108, 45);
      g.endFill();
    });

    const nameShadow = useCallback((g) => {
      g.clear();

      g.beginFill(0xffffff, 0.00000001);
      g.drawRect(-83, -50, 174, 36);
      g.endFill();
    });

    const textShadow = useCallback((g) => {
      g.clear();

      g.beginFill(0xffffff, 0.00000001);
      g.drawRect(-83, 2, 174, 88);
      g.endFill();
    });

    const dateShadow = useCallback((g) => {
      g.clear();

      g.beginFill(0xffffff, 0.00000001);
      g.drawRect(-83, 102, 74, 36);
      g.endFill();
    });

    const signShadow = useCallback((g) => {
      g.clear();

      g.beginFill(0xffffff, 0.00000001);
      g.drawRect(17, 102, 74, 36);
      g.endFill();
    });

    const fingerprintShadow = useCallback((g) => {
      g.clear();

      g.beginFill(0xffffff, 0.00000001);
      g.drawRect(-25, 142, 57, 52);
      g.endFill();
    });

    useEffect(() => {
      if (placed.current < 7) {
        if (!message.visible) {
          updateTime();
        } else {
          setCurrentTime(0);
        }
      }
    }, [currentTime, message]);

    const updateTime = () => {
      if (currentTime < TIME_LIMIT) {
        setTimeout(() => {
          setCurrentTime(currentTime + 1);
        }, 1000);
      } else {
        setMessage({ status: false, visible: true });
        setCurrentTime(0);
      }
    };

    const handleMouse = (e) => {
      setMousePosition([
        (e.data.global.x - window.innerWidth / 2) / scale,
        (e.data.global.y - window.innerHeight / 2) / scale,
      ]);
    };

    const handleMouseDown = (i) => {
      draggables[i].isDragging = true;
      setDraggables([...draggables]);
      setSelected(i);
    };

    const placeDraggable = (i) => {
      if (selected + 1 && i === selected) {
        draggables[selected].isDragging = false;
        draggables[selected].state = true;
        setDraggables([...draggables]);
        setSelected(-1);
        placed.current++;
        if (placed.current === 7) {
          setTimeout(() => {
            setMessage({ status: true, visible: true });
          }, 500);
        }
      } else {
        handleMouseUp();
      }
    };

    const handleMouseUp = () => {
      if (selected + 1) {
        draggables[selected].isDragging = false;
        setDraggables([...draggables]);
        setSelected(-1);
      }
    };

    const closeDialog = () => {
      tries.current++;
      if (message.status) {
        close();
      } else {
        setSelected(-1);
        setDraggables([
          ...draggables.map((e) => {
            e.state = false;
            e.isDragging = false;
            return e;
          }),
        ]);
        placed.current = 0;
        //close();
        setTimeout(() => {
          message.visible = false;
          setMessage({ ...message });
        }, 100);
      }
    };

    return (
      <>
        <Container
          scale={message.visible ? 0 : 1}
          alpha={message.visible ? 0 : 1}
          pivot={[-window.innerWidth / 2, -window.innerHeight / 1.8]}
          interactive
        >
          <Sprite image={rasterizeSVG(phone, [450, 450])} anchor={0.5} />

          <Graphics
            draw={container}
            interactive
            mousemove={handleMouse}
            mouseup={handleMouseUp}
          />
          <Graphics
            draw={profuturoShadow}
            interactive
            mouseup={() => placeDraggable(0)}
          />
          <Graphics
            draw={avatarShadow}
            interactive
            mouseup={() => placeDraggable(1)}
          />
          <Graphics
            draw={nameShadow}
            interactive
            mouseup={() => placeDraggable(2)}
          />
          <Graphics
            draw={textShadow}
            interactive
            mouseup={() => placeDraggable(3)}
          />
          <Graphics
            draw={dateShadow}
            interactive
            mouseup={() => placeDraggable(4)}
          />
          <Graphics
            draw={signShadow}
            interactive
            mouseup={() => placeDraggable(5)}
          />
          <Graphics
            draw={fingerprintShadow}
            interactive
            mouseup={() => placeDraggable(6)}
          />

          <Sprite
            image={rasterizeSVG(profuturo, [68, 68])}
            anchor={0.5}
            position={
              draggables[0].state
                ? draggables[0].finalPos
                : draggables[0].isDragging
                ? [mousePosition[0], mousePosition[1] - 45 * scale]
                : draggables[0].initPos
            }
            scale={selected === 0 && !draggables[0].state ? 1 : 0.8}
            interactive={!(selected + 1)}
            mousedown={() => handleMouseDown(0)}
          />
          <Sprite
            image={rasterizeSVG(avatar, [180, 180])}
            anchor={0.5}
            position={
              draggables[1].state
                ? draggables[1].finalPos
                : draggables[1].isDragging
                ? [mousePosition[0], mousePosition[1] - 10 * scale]
                : draggables[1].initPos
            }
            scale={selected === 1 && !draggables[1].state ? 1 : 0.8}
            interactive={!(selected + 1)}
            mousedown={() => handleMouseDown(1)}
          />
          <Sprite
            image={rasterizeSVG(name, [215, 215])}
            anchor={0.5}
            position={
              draggables[2].state
                ? draggables[2].finalPos
                : draggables[2].isDragging
                ? [mousePosition[0], mousePosition[1] - 40 * scale]
                : draggables[2].initPos
            }
            scale={selected === 2 && !draggables[2].state ? 1 : 0.8}
            interactive={!(selected + 1)}
            mousedown={() => handleMouseDown(2)}
          />
          <Sprite
            image={rasterizeSVG(text, [216, 216])}
            anchor={0.5}
            position={
              draggables[3].state
                ? draggables[3].finalPos
                : draggables[3].isDragging
                ? [mousePosition[0], mousePosition[1] - 50 * scale]
                : draggables[3].initPos
            }
            scale={selected === 3 && !draggables[3].state ? 1 : 0.8}
            interactive={!(selected + 1)}
            mousedown={() => handleMouseDown(3)}
          />
          <Sprite
            image={rasterizeSVG(date, [95, 95])}
            anchor={0.5}
            position={
              draggables[4].state
                ? draggables[4].finalPos
                : draggables[4].isDragging
                ? [mousePosition[0], mousePosition[1] - 50 * scale]
                : draggables[4].initPos
            }
            scale={selected === 4 && !draggables[4].state ? 1 : 0.8}
            interactive={!(selected + 1)}
            mousedown={() => handleMouseDown(4)}
          />
          <Sprite
            image={rasterizeSVG(sign, [95, 95])}
            anchor={0.5}
            position={
              draggables[5].state
                ? draggables[5].finalPos
                : draggables[5].isDragging
                ? [mousePosition[0], mousePosition[1] - 50 * scale]
                : draggables[5].initPos
            }
            scale={selected === 5 && !draggables[5].state ? 1 : 0.8}
            interactive={!(selected + 1)}
            mousedown={() => handleMouseDown(5)}
          />
          <Sprite
            image={rasterizeSVG(fingerprint, [65, 65])}
            anchor={0.5}
            position={
              draggables[6].state
                ? draggables[6].finalPos
                : draggables[6].isDragging
                ? [mousePosition[0], mousePosition[1] - 50 * scale]
                : draggables[6].initPos
            }
            scale={selected === 6 && !draggables[6].state ? 1 : 0.8}
            interactive={!(selected + 1)}
            mousedown={() => handleMouseDown(6)}
          />
          <ProgressBar
            value={currentTime}
            limit={TIME_LIMIT}
            x={-250}
            y={-530}
          />
        </Container>
        {message.visible && (
          <GameDialog
            image={message.status ? gameOk : gameWrong}
            button={message.status ? "Continuar" : "Jugar"}
            close={closeDialog}
            skip={() => close()}
            showSkip={tries.current > 2 && !message.status}
            scale={message.visible ? 1 : 0}
            alpha={message.visible ? 1 : 0}
          />
        )}
      </>
    );
  };
  return <Animated />;
}
