import TreeNode from "../../../structures/TreeNode";

const pos1 = new TreeNode(0, false, -2300, 0, "", 0);

const pos2 = new TreeNode(0, false, -2995, 400, "", 0);

const pos3 = new TreeNode(0, false, -3655, 538, "", 0);

const pos4 = new TreeNode(0, true, -3755, 888, "", 0);

const pos5 = new TreeNode(0, true, -3620, 1295, "", 0);

const pos6 = new TreeNode(0, false, -3990, 1540, "", 0);

const node1 = new TreeNode(
  0,
  false,
  -2300,
  0,
  "Necesito conseguir dinero para apoyar a mis nietos, no puedo permitir que se queden sin estudiar.",
  0
);

node1.quotes = [
  "“Tuve que sacar el préstamo porque a mi hijo no le alcanzaba para la inscripción de mis nietos”",
];

const node1_1 = new TreeNode(
  0,
  false,
  -2300,
  0,
  "Pensándolo bien, si mi hijo se entera que pedí un préstamo para esto me va a matar.",
  0
);

const node1_2 = new TreeNode(2, false, -2300, 0, "Juego 1 (Próximamente)", 0);

node1_2.game = 16;

const node1_3 = new TreeNode(
  0,
  false,
  -2300,
  0,
  "No encontré nada entre mis papeles, creí que alguna vez alguien me había ofrecido algo, pero siento que a los pensionados no nos ofrecerían nada.",
  2
);

const node2_2 = new TreeNode(
  0,
  false,
  -3655,
  538,
  "El banco no me quiso prestar porque soy pensionado y ya no tengo otro ingreso formal más que mi pensión, no sé qué hacer. Buscaré entre mis papeles a ver si encuentro algo.",
  0
);

node2_2.quotes = [
  "“Fue difícil encontrar el teléfono para que me vendieran el préstamo, es como si no quisieran que lo contrates”",
];

const node3_1 = new TreeNode(2, false, -3655, 538, "Pasan 3 días", 3 * 24);

const node3_2 = new TreeNode(
  0,
  false,
  -3655,
  538,
  "No he podido conseguir nada en 3 días, me siento un poco desesperado.",
  0
);

const node3_3 = new TreeNode(2, false, -3655, 538, "Suena el teléfono.", 0);

const node3_4 = new TreeNode(
  0,
  false,
  -3655,
  538,
  "Es un número desconocido, pero me han marcado ya un par de veces, veré qué quieren.",
  0
);

const node4_1 = new TreeNode(
  1,
  true,
  -3755,
  888,
  "Hola, Sr. Pérez, le hablamos de Profuturo, ¿está ocupado?",
  0
);

node4_1.dialogAnchor = { x: 7 };

const node4_2 = new TreeNode(
  0,
  true,
  -3755,
  888,
  "No estoy ocupado, dígame.",
  0
);

const node4_3 = new TreeNode(
  1,
  true,
  -3755,
  888,
  "Le marco para ofrecerle un préstamo, ya que usted como pensionado, tiene derecho a uno, ¿le gustaría que le diera más información?",
  0
);

node4_3.dialogAnchor = { x: 7 };

const node4_4 = new TreeNode(
  0,
  true,
  -3755,
  888,
  "Perfecto, justo estaba en busca de un préstamo.",
  0
);

node4_4.quotes = [
  "“Es un alivio que sea tan fácil acceder al préstamo, por eso los escogí por este beneficio que proporcionaban”",
];

const node4_5 = new TreeNode(
  1,
  true,
  -3755,
  888,
  "Perfecto, para ser candidato para recibir el préstamo debe de recibir por lo menos 3 mil pesos de pensión, y ésta se la debe de pagar el IMSS, adicional debe de tener un comprobante de domicilio de no más de tres meses y una identificación oficial y no tener otro préstamo contratado, ¿cumple con estos requisitos?",
  0
);

node4_5.dialogAnchor = { x: 7 };

node4_5.quotes = [
  "“No tenía muchas opciones, ellos me dan un préstamo aunque la tasa sea alta, por eso lo contraté”",
];

const node4_6 = new TreeNode(
  0,
  true,
  -3755,
  888,
  "Sí, los cumplo todos, ¿cuáles son las condiciones para contratarlo? Tengo muchas dudas y me gustaría entender bien antes de contratarlo, además me interesa que se haga rápido el depósito, ¿cuánto se tardan?",
  0
);

const node4_7 = new TreeNode(
  1,
  true,
  -3755,
  888,
  "Nos tardamos de 24 a 72 horas para hacer el depósito, si gusta puedo agendarle una cita con alguno de nuestros asesores para que le explique los beneficios y condiciones del préstamo.",
  0
);

node4_7.dialogAnchor = { x: 7 };

const node4_8 = new TreeNode(
  0,
  true,
  -3755,
  888,
  "Sí, eso estaría bien, ¿podría ser mañana a las 5, en la cafetería que está en Miguel Laurent? No quiero que sea en mi casa porque no quiero que mi hijo se entere.",
  0
);

const node4_9 = new TreeNode(
  1,
  true,
  -3755,
  888,
  "Claro, ahí lo verá el asesor.",
  1
);

node4_9.dialogAnchor = { x: 7 };

const node5_1 = new TreeNode(2, true, -3620, 1295, "Pasa un día.", 24);

const node5_3 = new TreeNode(
  1,
  true,
  -3620,
  1295,
  "Hola Sr. Juárez, mi nombre es Juan y estoy aquí para resolver todas sus dudas sobre el préstamo.",
  0
);

node5_3.dialogAnchor = { y: 2 };

const node5_4 = new TreeNode(
  0,
  true,
  -3620,
  1295,
  "Hola Juan, en realidad me gustaría saber ¿Cómo me lo cobrarían y cuál es la tasa de interés?",
  0
);

const node5_5 = new TreeNode(
  1,
  true,
  -3620,
  1295,
  "El cobro del préstamo se hace directo de la pensión, no se tendría que preocupar por pagar y la tasa de interés es de 29.9% es una tasa fija.",
  0
);

node5_5.dialogAnchor = { y: 2 };

const node5_6 = new TreeNode(
  0,
  true,
  -3620,
  1295,
  "¿30.37? ¡¡Es altísima!! Pero en verdad necesito el dinero, está bien sí lo quiero.",
  0
);

node5_6.quotes = [
  "“Al momento de la venta, no entiendo porque te piden hacer tantas cosas, sólo quiero conratar el préstamo, no entiendo porqué tiene que validar tanto”",
  "“La verdad es que no entendí nada, pedí un préstamo y terminé pagando el triple!! Nunca me dijeron cuánto iba a pagar, siento que me vieron la cara, son muy doblecara”",
];

const node5_7 = new TreeNode(
  1,
  true,
  -3620,
  1295,
  "Perfecto podemos tramitarlo de una vez, sólo necesitamos tener cierta documentación.",
  0
);

node5_7.dialogAnchor = { y: 2 };

const node5_8 = new TreeNode(
  0,
  true,
  -3620,
  1295,
  "Podemos ir a mi casa por los documentos.",
  0
);

const node5_9 = new TreeNode(2, true, -3620, 1295, "Juego 2 (Próximamente)", 1);

node5_9.game = 15;

const node5_10 = new TreeNode(
  1,
  true,
  -3620,
  1295,
  "Listo ya quedó su solicitud, de 24 horas a 72 horas le depositan el dinero.",
  0
);

node5_10.dialogAnchor = { y: 2 };

const node6_1 = new TreeNode(2, false, -3990, 1540, "Al día siguiente.", 24);

const node6_2 = new TreeNode(
  0,
  false,
  -3990,
  1540,
  "El asesor me dijo que se tardaban de 24 horas a 72 horas, ¿cuánto más se tardará? Ya quiero sorprender a mi hijo con el dinero para la inscripción.",
  24
);

const node6_3 = new TreeNode(2, false, -3990, 1540, "Unas horas después.", 3);

const node6_4 = new TreeNode(
  0,
  false,
  -3990,
  1540,
  "Ya me llegó el mensaje del depósito, ¡que paz, ya podré decirle a mi hijo! Estoy preocupado por cuánto voy a pagar al final pero la familia lo vale.",
  0
);

node6_4.quotes = [
  "“La verdad es que estuvo bien que te depositen el dinero rápido, eso quiere decir que sí piensan en nosotros porque obvio saben que lo necesitas”",
  "“Fue un poco engorroso que me hicieran mandar muchas veces mis documentos por un tema con el banco, justo es un tema con el banco, deberían de verlo con ellos, pero bueno, al menos después me depositaron rápido”",
];

const node7 = new TreeNode(2, false, -3990, 1540, "Final", 0);

node7.isLast = true;
node7.ending = 0;

//node1_2
node1.descendants = [
  {
    node: node1_1,
    hint: "Le pediré a alguno de mis nietos que me ayude a investigar.",
  },
  {
    node: node1_2,
    hint: "Buscaré entre todos mis papeles, seguro alguien me ha ofrecido un préstamo.",
  },
];

node1_1.descendants = [
  {
    node: node1_2,
    hint: "Mejor sí buscaré entre mis papeles",
  },
  {
    node: node2_2,
    hint: "Me acercaré a un banco",
  },
];

node1_2.descendants = [
  {
    node: node1_3,
    hint: "Continuar",
  },
];

node1_3.descendants = [
  {
    node: node3_1,
    hint: "Continuar",
  },
];

node2_2.descendants = [
  {
    node: node3_1,
    hint: "Continuar",
  },
];

node3_1.descendants = [
  {
    node: node3_2,
    hint: "Continuar",
  },
];

node3_2.descendants = [
  {
    node: node3_3,
    hint: "Continuar",
  },
];

node3_3.descendants = [
  {
    node: node3_4,
    hint: "Continuar",
  },
];

node3_4.descendants = [
  {
    node: node4_1,
    hint: "Contestar la llamada",
  },
];

node4_1.descendants = [
  {
    node: node4_2,
    hint: "Continuar",
  },
];

node4_2.descendants = [
  {
    node: node4_3,
    hint: "Continuar",
  },
];

node4_3.descendants = [
  {
    node: node4_4,
    hint: "Continuar",
  },
];

node4_4.descendants = [
  {
    node: node4_5,
    hint: "Continuar",
  },
];

node4_5.descendants = [
  {
    node: node4_6,
    hint: "Continuar",
  },
];

node4_6.descendants = [
  {
    node: node4_7,
    hint: "Continuar",
  },
];

node4_7.descendants = [
  {
    node: node4_8,
    hint: "Continuar",
  },
];

node4_8.descendants = [
  {
    node: node4_9,
    hint: "Continuar",
  },
];

node4_9.descendants = [
  {
    node: node5_1,
    hint: "Continuar",
  },
];

node5_1.descendants = [
  {
    node: node5_3,
    hint: "Continuar",
  },
];

node5_3.descendants = [
  {
    node: node5_4,
    hint: "Continuar",
  },
];

node5_4.descendants = [
  {
    node: node5_5,
    hint: "Continuar",
  },
];

node5_5.descendants = [
  {
    node: node5_6,
    hint: "Continuar",
  },
];

node5_6.descendants = [
  {
    node: node5_7,
    hint: "Continuar",
  },
];

node5_7.descendants = [
  {
    node: node5_8,
    hint: "Continuar",
  },
];

node5_8.descendants = [
  {
    node: node5_9,
    hint: "Continuar",
  },
];

node5_9.descendants = [
  {
    node: node5_10,
    hint: "Continuar",
  },
];

node5_10.descendants = [
  {
    node: node6_1,
    hint: "Continuar",
  },
];

node6_1.descendants = [
  {
    node: node6_2,
    hint: "Continuar",
  },
];

node6_2.descendants = [
  {
    node: node6_3,
    hint: "Continuar",
  },
];

node6_3.descendants = [
  {
    node: node6_4,
    hint: "Continuar",
  },
];

node6_4.descendants = [
  {
    node: node7,
    hint: "Continuar",
  },
];

export default node1;
