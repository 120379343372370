import React, { useState } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { setComment, setCommentError } from "../redux/features/general";

export default function CommentContainer() {
  const [isBtnPressed, setBtnPressed] = useState();
  const dispatch = useDispatch();
  const { comment, commentError } = useSelector((state) => state.general);

  const mouseUp = () => {
    setBtnPressed(false);
  };

  const mouseDown = () => {
    setBtnPressed(true);
  };

  const handleText = (e) => {
    if (commentError) dispatch(setCommentError(""));
    dispatch(setComment(e.target.value));
  };

  return (
    <div className="commentContainer">
      <div className="title">
        Ahora que viviste esta experiencia como cliente, ¿qué harías tú para
        mejorarla?
      </div>
      <div className="thanks">
        ¡Muchas gracias! Estas conclusiones nos ayudan a mejorar la experiencia
        de nuestros clientes.
        <br />
        Recuerda que aunque no tengas contacto, tienes impacto.
      </div>
      <textarea
        className="txt"
        onChange={handleText}
        value={comment}
        maxLength={1000}
      ></textarea>
      <span className={"error" + (commentError ? " active" : "")}>
        {commentError}
      </span>
    </div>
  );
}
