import { configureStore } from "@reduxjs/toolkit";
import map from "./features/map/map";
import avatar from "./features/map/avatar";
import general from "./features/general";
import info from "./features/info";
import transition from "./features/transition";
import registry from "./features/registry/registry";
import registryTime from "./features/registry/time";
import withdrawals from "./features/withdrawals/withdrawals";
import withdrawalsTime from "./features/withdrawals/time";
import dataUpdate from "./features/dataUpdate/dataUpdate";
import dataUpdateTime from "./features/dataUpdate/time";
import fullWithdrawal from "./features/fullWithdrawal/fullWithdrawal";
import fullWithdrawalTime from "./features/fullWithdrawal/time";
import pension from "./features/pension/pension";
import pensionTime from "./features/pension/time";
import loans from "./features/loans/loans";
import loansTime from "./features/loans/time";
import video from "./features/video";
import audio from "./features/audio";
import user from "./features/user";

export default configureStore({
  reducer: {
    map,
    avatar,
    general,
    transition,
    registry,
    registryTime,
    withdrawals,
    withdrawalsTime,
    dataUpdate,
    dataUpdateTime,
    fullWithdrawal,
    fullWithdrawalTime,
    video,
    audio,
    user,
    pension,
    pensionTime,
    loans,
    loansTime,
    info,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});
