import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import map from "../../assets/fullWithdrawal/map.js";
import Back from "../../baseComponents/Back";
import Dialog from "../../baseComponents/Dialog";
import {
  setCurrentNode,
  resetNode,
} from "../../redux/features/fullWithdrawal/fullWithdrawal";
import { Spring } from "react-spring";
import {
  AnimatedSprite,
  Container,
  Sprite,
  TilingSprite,
} from "@inlet/react-pixi/animated";
import LottieSprite from "../../baseComponents/LottieSprite";
import NodeAnimation from "../../baseComponents/NodeAnimation";
import data from "../../assets/anim/cat.json";
import data2 from "../../assets/anim/404.json";
import data3 from "../../assets/anim/smile.json";
import { easeCubicInOut } from "d3-ease";
import { setTransition } from "../../redux/features/transition";
import GameContainer from "../../baseComponents/GameContainer/Index";
import data4 from "../../assets/anim/scroll_up_correct.json";
import { setSection } from "../../redux/features/general.js";
import Avatar from "./components/Avatar";
import TimeCount from "./components/TimeCount.js";
import laptop from "../../assets/anim/laptop.json";
import trees from "../../assets/map/trees/tree1.png";
import TitleContainer from "../../baseComponents/TitleContainer.js";
import image from "../../assets/fullWithdrawal/Mensaje_bienvenida_mundo_4.png";
import ending1 from "../../assets/fullWithdrawal/Mensaje_impacto_mundo4.png";
import ending2 from "../../assets/fullWithdrawal/Mensaje_impacto_mundo4_2.png";
import useWindowSize from "../../utils/useWindowSize";
import ReactLottie from "../../baseComponents/ReactLottie.js";
import Quotes from "../../baseComponents/Quotes.js";
import { addTime, resetTime } from "../../redux/features/fullWithdrawal/time";
import rasterizeSVG from "../../utils/rasterizeSVG.js";
import { interruptAudio } from "../../redux/features/audio.js";
import LottiePixiLoader from "../../utils/LottiePixiLoader.js";
import phoneGuy from "../../assets/anim/phone guy.json";
import search from "../../assets/anim/laptop search.json";
import desk from "../../assets/anim/desk guy.json";
import woman from "../../assets/anim/woman.json";

export default function FullWithdrawal() {
  const spring = { mass: 1, tension: 100, friction: 50 };

  const Animated = () => {
    useWindowSize();
    const scale = window.innerWidth / 1536;
    const frames = LottiePixiLoader(
      [358, 535, 300, 105],
      [phoneGuy, search, desk, woman]
    );
    const [isAnimationsPlaying, setAnimationsPlaying] = useState(false);
    const [showTitle, setShowTitle] = useState(0);
    const [titleImage, setTitleImage] = useState(image);
    const [showAvatar, setShowAvatar] = useState(false);
    const [avatarStart, setAvatarStart] = useState(true);
    const [showQuotes, setShowQuotes] = useState(1);
    const dispatch = useDispatch();

    const { currentNode, anchor, timeCount } = useSelector(
      (state) => state.fullWithdrawal
    );
    const { info } = useSelector((state) => state.info);
    const lastNode = useRef(currentNode);
    const endingImage = [ending1, ending2];

    useEffect(() => {
      /*
      dispatch(setTransition(false));

      setTimeout(() => {
        setShowTitle(1);
      }, 1000);
      */
      return () => {
        dispatch(resetNode());
        //dispatch(resetTime());
      };
    }, []);

    useEffect(() => {
      if (!!frames.length) {
        setTimeout(() => {
          dispatch(setTransition(false));

          setTimeout(() => {
            if (!showTitle) setShowTitle(1);
          }, 1000);
        }, 500);
      }
    }, [frames]);

    useEffect(() => {
      if (currentNode.isLast) {
        if (typeof currentNode.ending === "number") {
          setTitleImage(endingImage[currentNode.ending]);
        }
        setShowTitle(info ? 0 : 1);
      }
    }, [currentNode, info]);

    const handleAudio = (interrupted) => {
      //console.log(interrupted);
      dispatch(interruptAudio(interrupted));
    };

    const onAction = (id) => {
      dispatch(setCurrentNode(currentNode.descendants[id].node || 0));
      lastNode.current = currentNode;
      setShowQuotes(1);
      //dispatch(addTime(currentNode.time || 0));
    };

    const handleBack = () => {
      dispatch(setTransition(true));
      setTimeout(() => {
        dispatch(setSection("menu"));
        dispatch(resetTime());
      }, 1000);
    };

    const handleDialogClose = () => {
      setShowQuotes(0);
    };

    const onCloseGame = (stay, gameId = 0) => {
      dispatch(
        setCurrentNode(
          stay ? lastNode.current : currentNode.descendants[gameId].node
        )
      );
    };

    return (
      <Spring
        to={{
          x: currentNode.anchor.x * scale,
          y: currentNode.anchor.y * scale,
          anchor: [window.innerWidth / 2.1, window.innerHeight / 2],
        }}
        config={{ duration: 1000, easing: easeCubicInOut }}
      >
        {(props) => (
          <Container>
            <Container position={props.anchor}>
              <Container {...props} pivot={[0, scale * 2024 * 2.3]}>
                <Sprite
                  image={rasterizeSVG(map, [
                    scale * 1789 * 2.3,
                    scale * 2024 * 2.3,
                  ])}
                />
                {!!frames.length && (
                  <>
                    <Container
                      position={[3205 * scale, 3000 * scale]}
                      scale={[-1, 1]}
                    >
                      <AnimatedSprite
                        position={[0, 0]}
                        textures={frames[0]}
                        isPlaying={isAnimationsPlaying}
                        initialFrame={0}
                        animationSpeed={0.3}
                      />
                    </Container>
                    <Container position={[3345 * scale, 2590 * scale]}>
                      <AnimatedSprite
                        position={[0, 0]}
                        textures={frames[1]}
                        isPlaying={isAnimationsPlaying}
                        initialFrame={0}
                        animationSpeed={0.5}
                      />
                    </Container>
                    <Container position={[2600 * scale, 2130 * scale]}>
                      <AnimatedSprite
                        position={[0, 0]}
                        textures={frames[2]}
                        isPlaying={isAnimationsPlaying}
                        initialFrame={0}
                        animationSpeed={0.3}
                      />
                    </Container>
                    <Container
                      position={[3055 * scale, 2440 * scale]}
                      scale={[-1, 1]}
                    >
                      <AnimatedSprite
                        position={[0, 0]}
                        textures={frames[3]}
                        isPlaying={isAnimationsPlaying}
                        initialFrame={0}
                        animationSpeed={0.5}
                      />
                    </Container>
                    <Container position={[2485 * scale, 1660 * scale]}>
                      <AnimatedSprite
                        position={[0, 0]}
                        textures={frames[2]}
                        isPlaying={isAnimationsPlaying}
                        initialFrame={0}
                        animationSpeed={0.3}
                      />
                    </Container>
                    <Container position={[3125 * scale, 1530 * scale]}>
                      <AnimatedSprite
                        position={[0, 0]}
                        textures={frames[3]}
                        isPlaying={isAnimationsPlaying}
                        initialFrame={0}
                        animationSpeed={0.5}
                      />
                    </Container>
                    <Container position={[2670 * scale, 1140 * scale]}>
                      <AnimatedSprite
                        position={[0, 0]}
                        textures={frames[2]}
                        isPlaying={isAnimationsPlaying}
                        initialFrame={0}
                        animationSpeed={0.3}
                      />
                    </Container>
                  </>
                )}
              </Container>
            </Container>
            {showAvatar && (
              <Container position={props.anchor} scale={scale}>
                <Avatar
                  anchor={currentNode.anchor}
                  prevDirection={lastNode.current.direction}
                  direction={currentNode.direction}
                  begin={avatarStart}
                />
              </Container>
            )}
            <Back click={handleBack} />
            {showAvatar && (
              <Quotes
                quotes={currentNode.quotes}
                visible={showQuotes}
                ageRange={[40, 60]}
                titleColor="#092C74"
                position={
                  (currentNode.direction && currentNode.type === 0) ||
                  (!currentNode.direction && currentNode.type === 1)
                }
              />
            )}
            {typeof currentNode.game !== "number" &&
              showAvatar &&
              !currentNode.isLast && (
                <Dialog
                  id={currentNode}
                  {...currentNode.dialogAnchor}
                  direction={currentNode.direction}
                  delay={
                    currentNode.anchor.x === lastNode.current.anchor.x &&
                    currentNode.anchor.y === lastNode.current.anchor.y
                      ? 0
                      : 2000
                  }
                  message={currentNode.message}
                  buttons={
                    currentNode.descendants
                      ? currentNode.descendants.map((e, i) => {
                          e.key = i;
                          //console.log(e);
                          return e.hint;
                        })
                      : []
                  }
                  type={currentNode.type}
                  action={onAction}
                  currentNode={currentNode}
                  addTime={addTime}
                  onClose={handleDialogClose}
                  buttonsActive={true || typeof currentNode.audio !== "number"}
                />
              )}
            <GameContainer
              descendants={currentNode.descendants}
              id={currentNode.game}
              lastId={lastNode.current && lastNode.current.game}
              close={(stay, gameId) => onCloseGame(stay, gameId)}
              back={() => handleBack()}
            />
            <TitleContainer
              image={titleImage}
              scale={showTitle}
              isLast={currentNode.isLast}
              onVideoVisible={() => handleAudio(true)}
              hasEndingMessage={typeof currentNode.ending === "number"}
              commentPos={0}
              close={() => {
                if (currentNode.isLast) handleBack();
                setAnimationsPlaying(true);
                setShowTitle(0);
                setShowAvatar(true);
                setAvatarStart(false);
                handleAudio(false);
              }}
              buttonsPosition={[
                window.innerWidth / 4.8,
                window.innerHeight / 2 + 225 * scale,
              ]}
              worldType={3}
            />
            <TimeCount time={timeCount} />
          </Container>
        )}
      </Spring>
    );
  };

  return <Animated />;
}
