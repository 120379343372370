import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import medal from "../../../assets/medal.svg";
import { getComments, getPlayers } from "../../../services/users";
import LogOut from "../../LogOut";

export default function Rank() {
  let [players, setPlayers] = useState([]);
  let [firstPlayer, setFirstPlayer] = useState();
  let [comments, setComments] = useState([]);
  const { userName } = useSelector((state) => state.user);
  const worlds = [
    "¿DÓNDE ESTÁ MI AHORRO PARA EL RETIRO?",
    "NECESITO CONSEGUIR DINERO, ME QUEDÉ SIN EMPLEO",
    "CON ESA PENSIÓN NO ME ALCANZA, QUIERO MEJORARLA",
    "¿QUÉ TENGO QUE HACER PARA PENSIONARME?",
    "SOY VIUDA ¿CÓMO HAGO PARA QUE LE SIGAN PAGANDO LA PENSIÓN A MIS HIJOS?",
    "¿CÓMO PUEDO APOYAR A MI FAMILIA SI SOLO TENGO MI PENSIÓN?",
  ];

  useEffect(() => {
    getPlayers(userName).then((res) => {
      let firstPlayer;
      let players = res.data.Players;
      if (players) firstPlayer = players.shift();
      setFirstPlayer(firstPlayer);
      setPlayers(players);
    });
  }, []);

  useEffect(() => {
    getComments(userName).then((res) => {
      setComments(res.data.Comments);
    });
  }, []);

  const downloadComments = () => {
    const items = comments.map((e) => {
      e.Mundo = worlds[e.Mundo];
      return e;
    });
    const replacer = (key, value) => (value === null ? "" : value); // specify how you want to handle null values here
    const header = Object.keys(items[0]);
    const csv = [
      header.join(","), // header row first
      ...items.map((row) =>
        header
          .map((fieldName) => JSON.stringify(row[fieldName], replacer))
          .join(",")
      ),
    ].join("\n");

    let encodedUri = encodeURI("data:text/csv;charset=utf-8," + csv);
    let link = document.createElement("a");
    link.setAttribute("href", encodedUri);
    link.setAttribute("download", "Comentarios.csv");
    document.body.appendChild(link);

    link.click();
  };

  return (
    <div id="rank">
      <div className="header">
        <img alt="medalla" src={medal} className="medal" />
        <div className="user">{firstPlayer && firstPlayer.user_name}</div>
        <div className="score">
          {firstPlayer
            ? firstPlayer.total_score
            : "No hay registro de jugadores"}
        </div>
        {firstPlayer && comments.length && (
          <div className="comments" onClick={downloadComments}>
            Descargar comentarios
          </div>
        )}
      </div>
      <div className="list">
        {players &&
          players.length &&
          players.map((e, i) => (
            <div key={i} className="item">
              <div className="left">
                <div className="num">{i + 2}</div>
                <div>{e.user_name}</div>
              </div>
              <div>{e.total_score}</div>
            </div>
          ))}
      </div>

      <LogOut navigateTo={"/admin"} />
    </div>
  );
}
