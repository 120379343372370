import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { removeUser } from "../redux/features/user";
import logout from "../assets/logout.svg";

export default function LogOut({ navigateTo }) {
  const { userName, role } = useSelector((state) => state.user);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const onClick = () => {
    window.localStorage.removeItem("userName", userName);
    window.localStorage.removeItem("userRole", role);
    dispatch(removeUser());
    navigate(navigate || "/");
    window.location.reload();
  };

  return (
    <div id="log-out" onClick={onClick}>
      <img alt="Cerrar sesión" src={logout} />
    </div>
  );
}
