import TreeNode from "../../../structures/TreeNode";
import random from "../../../utils/random";

const node1 = new TreeNode(
  0,
  false,
  -1400,
  690,
  "Me gustaría saber más sobre el retiro y mi pensión, ya siento más cerca esa etapa y no tengo ni idea de nada.",
  0
);

node1.quotes = [
  "“No había pensado sobre mi pensión, me daba la sensación de que eso lo tenía que ver ya cercano a mi retiro, además nadie te dice nada del tema”",
];

const node1_1 = new TreeNode(
  0,
  false,
  -1400,
  690,
  "Mis amigos me comentaron que podía meterme a buscar en internet y que lo más seguro es que mi Afore tenga una calculadora de pensión.",
  0
);

const node1_2 = new TreeNode(
  0,
  false,
  -1400,
  690,
  "Mi Afore es Profuturo, así que ingresaré a su sitio web para ver si encuentro algo sobre el tema.",
  0
);

const node2 = new TreeNode(
  0,
  false,
  -2250,
  1160,
  "Encontré que Profuturo tiene una app que te da una estimación de pensión y en su misma página tienen una calculadora.",
  random([1, 2])
);

const node2_1 = new TreeNode(
  0,
  false,
  -2250,
  1160,
  "Listo ya me registré en la app, y en la primer pantalla ya vi la pensión que tengo hasta el momento, ¡no es nada! ahí mismo me dan la opción de hacer aportaciones voluntarias, sólo que no estoy seguro de cómo funciona y para que sirve.",
  0
);

node2_1.quotes = [
  "“Te deberían de avisar antes y asesorarte para mejorar tu pensión”",
  "“Al ver de cuánto sería mi me sorprendi del monto, con eso no voy a poder vivir”",
  "“Me gusta que en la app de Profuturo ya me digan de cuánto será mi pensión”",
];

const node2_1_1 = new TreeNode(
  0,
  false,
  -2250,
  1160,
  "Ya investigué y veo que hay diferentes fondos dependiendo de lo que yo quiera, unos son para la pensión y no se pueden retirar hasta que yo me retire y otros sí te da chance de retirarlos, con esto me quedo más tranquilo y haré las aportaciones desde la app.",
  random([1, 2])
);

node2_1_1.quotes = [
  "“Inicié a hacer ahorro voluntario desde que me cambié a Profuturo, el asesor me explicó su importancia y se lo agradezco mucho”",
];

const node2_2 = new TreeNode(
  0,
  false,
  -2250,
  1160,
  "Listo ya hice el cálculo, ¡no es nada! ahí mismo me dicen que puedo hacer algo que se llama aportaciones voluntarias y se ve que aumenta mi pensión pero, ¿dónde puedo hacerlas?",
  random([1, 2])
);

const node2_2_1 = new TreeNode(
  0,
  false,
  -2250,
  1160,
  "Ya investigué un poco, y las puedo hacer desde la app Profuturo Móvil, pero cómo es la primera vez, no estoy seguro si debo de pedir asesoría, ya que hay varios fondos y no sé cuál es la diferencia de cada uno.",
  random([1, 2])
);

const node2_2_1_1 = new TreeNode(
  0,
  false,
  -2250,
  1160,
  "Me tardé un poco pero ya encontré el dato de contacto del Call Center de Profuturo, marcaré.",
  0
);

const node2_2_1_1_1 = new TreeNode(2, false, -2250, 1160, "Llamando.", 0);

const node2_2_1_1_2 = new TreeNode(
  1,
  false,
  -2250,
  1160,
  "Hola, buen día. Está marcando a Profuturo, ¿cómo le podemos ayudar?",
  0
);

const node2_2_1_1_3 = new TreeNode(
  0,
  false,
  -2250,
  1160,
  "Gracias, quisiera saber cómo hacer aportaciones voluntarias a mi cuenta.",
  0
);

const node2_2_1_1_4 = new TreeNode(
  1,
  false,
  -2250,
  1160,
  "Claro, usted puede elegir la cantidad de las aportaciones voluntarias a realizar, estas pueden ser automáticas cada quincena o mes. Puede revisar toda la información desde la app. El realizar aportaciones voluntarias le da muchos beneficios al pensionarse.",
  0
);

const node2_2_1_1_5 = new TreeNode(
  0,
  false,
  -2250,
  1160,
  "Muy bien, gracias por la información.",
  0
);

const node2_2_1_2 = new TreeNode(
  0,
  false,
  -2250,
  1160,
  "Listo, marqué y me explicaron todo sobre cómo las aportaciones voluntarias, los fondos y sus beneficios, Estoy listo para hacerlas por la app Profuturo Móvil.",
  random([1, 2])
);

node2_2_1_2.quotes = [
  "“La idea es que con eso se mejore la pensión, pero ni si quiera sé si veré ese dinero”",
  "“La asistente me recomendó leer el blog de Profuturo, me gustó, es buen contenido y fácil”",
  "“Tuve que marcar para hacer una aportación a mi cuenta, de decirte cómo hacerlo, así no tienes aue marcar”",
];

const node3 = new TreeNode(
  0,
  false,
  -3350,
  1500,
  "Para comenzar, me pide escoger el fondo, de acuerdo a lo que busco, debe ser el de 65 años, ya que lo quiero para mejorar mi pension.",
  0
);

node3.quotes = [
  "“Dentro de la app fue muy fácil hacer la aportación, sólo no supe bien que fondo, pero lo demás estuvo fácil”",
  "“No entiendo bien las diferencias de los diferentes fondos, pero supongo son similares”",
  "“No sabía bien sobre los fondos que ofrecen, marqué y me explicaron muy bien”",
];

const node3_1 = new TreeNode(
  0,
  false,
  -3350,
  1500,
  "El tipo de aportación, será frecuente, ya que quiero aportar mensualmente.",
  0
);

const node3_2 = new TreeNode(0, true, -3350, 1500, "Juego 1", 0);

node3_2.game = 9;

const node3_2_1 = new TreeNode(
  0,
  false,
  -3350,
  1500,
  "Quiero que se me cobren 200 pesos cada quincena.",
  0
);

node3_2_1.quotes = [
  "“Quise sólo probar una para ver cómo funciona, y si todo está Ok pues ya haré otras en el futuro”",
];

const node3_2_2 = new TreeNode(
  0,
  false,
  -3350,
  1500,
  "Quiero que se me cobren 500 pesos cada quincena.",
  0
);

node3_2_2.quotes = [
  "“Quise sólo probar una para ver cómo funciona, y si todo está Ok pues ya haré otras en el futuro”",
];

const node3_2_3 = new TreeNode(
  0,
  false,
  -3350,
  1500,
  "Quiero que se me cobren 500 pesos cada quincena.",
  0
);

node3_2_3.quotes = [
  "“Quise sólo probar una para ver cómo funciona, y si todo está Ok pues ya haré otras en el futuro”",
];

const node3_3 = new TreeNode(
  0,
  false,
  -3350,
  1500,
  "Ahora tengo que dar de alta mi cuenta bancaria.",
  0
);

node3_3.quotes = [
  "“Uno quiere hacer algo por su retiro, y le ponen trabas, me tardé más en registrar mi cuenta de banco que en hacer una aportación”",
];

const node3_4 = new TreeNode(
  0,
  false,
  -3350,
  1500,
  "Listo, ya quedó programada, espero la siguiente quincena me notifiquen que todo está bien con la aportación.",
  0
);

const node4 = new TreeNode(2, false, -3350, 1500, "Pasan 5 días", 5 * 24);

const node4_1 = new TreeNode(
  0,
  false,
  -3350,
  1500,
  "Me acaba de llegar una notificación de que se aceptó mi cuenta bancaria, pero no recuerdo en qué trámite la utilicé, posiblemente sea por la aportación voluntaria que realicé hace unos días, pero no estoy seguro.",
  0
);

const node4_1_1 = new TreeNode(2, false, -4400, 2110, "Llamando.", 0);

const node4_1_1_1 = new TreeNode(
  1,
  false,
  -4400,
  2110,
  "Hola, buen día. Está marcando a Profuturo, ¿cómo le podemos ayudar?",
  0
);

const node4_1_1_2 = new TreeNode(
  0,
  false,
  -4400,
  2110,
  "Gracias, quisiera realizar aportaciones voluntarias a mi cuenta.",
  0
);

const node4_1_1_3 = new TreeNode(
  1,
  false,
  -4400,
  2110,
  "Claro, para hacer este tramite necesitamos certificar su cuenta una vez certificada las aportaciones se estarían realizando cada quincena.",
  0
);

const node4_1_1_4 = new TreeNode(
  0,
  false,
  -4400,
  2110,
  "Muy bien, gracias. Esperaré.",
  0
);

const node4_2 = new TreeNode(
  0,
  false,
  -4400,
  2110,
  "Listo, marqué y me comentaron que para poder hacer la aportación ellos tienen que certificar mi cuenta, y como no la tenia dada de alta, la certificaron por primera vez ahora sólo tengo que esperar a que se realice la aportación.",
  0
);

const node4_3 = new TreeNode(
  2,
  false,
  -4200,
  2710,
  "Pasan unos días (Llega la quincena.).",
  24 * random([2, 3])
);

const node4_4 = new TreeNode(
  0,
  false,
  -4200,
  2710,
  "Me llegó una notificación de que no se pudo hacer la aportación debido a que no había dinero en mi cuenta de banco, ya revisé mi saldo y todavía no me cae la quincena. Veré si puedo reprogramar la aportación.",
  0
);

node4_4.quotes = [
  "“Está buenísimo que nos mantengan informados, además cualquier cosa su gente nos atiende”",
];

const node4_6 = new TreeNode(
  0,
  false,
  -4200,
  2710,
  "Listo, ahora sí, espero que hoy me depositen y que mañana se haga la aportación sin problema.",
  0
);

const node4_7 = new TreeNode(
  0,
  false,
  -4200,
  2710,
  "Ya me llegó la notificación de que se cobró la aportación, aún no la veo reflejada en la app, pero me comentaron que se podía tardar un par de días en verse reflejada. Sólo que no quiero que me estén llegando notificaciones a este correo, así que voy a cambiar el mail, creo que en la app también se puede actualizar.",
  24
);

node4_7.quotes = [
  "“Nunca me llega la información de mi Afore”",
  "“Deberían de ser más insistentes en tener los datos de sus clientes actualizados, es importante que sepamos de cuánto será nuestra pensión poder hacer algo”",
];

const node5 = new TreeNode(
  0,
  true,
  -4200,
  2710,
  "No puedo entrar a la app, no sé por qué, me dice que hay un error de conexión pero sí tengo internet y ya intenté con mis datos. Intentaré más tarde.",
  0
);

const node5_1 = new TreeNode(
  0,
  true,
  -4200,
  2710,
  "Ya por fin pude ingresar a la app, que raro que hubo esa intermitencia, me asusté un poco, pero ya pude entrar.",
  random([1, 2])
);

node5_1.quotes = [
  "“Cuando intento retirar o cancelar una aportación por la app, no me deja, es nuestro dinero y no nos dejan sacarlo, ya hasta me preocupé si lo voy a ver algún día”",
  "“Hice el trámite para retirar mi dinero de los fondos de apovol, y todo fluyó muy bien, me asesoraron y ahora estoy feliz con mis ahorros”",
];

const node5_1_1 = new TreeNode(0, true, -4200, 2710, "Juego 2", 0);

node5_1_1.game = 8;

const node5_2 = new TreeNode(
  0,
  true,
  -4200,
  2710,
  "Ya actualicé mis datos, me comentan que queda en 5 días, esperaré la notificación.",
  0
);

node5_2.quotes = [
  "“En la app, no me decía cuando se iba a hacer mi actualización, entonces estuve esperando y metiéndome todos los días a revisar”",
];

const node5_3 = new TreeNode(
  0,
  true,
  -4200,
  2710,
  "Listo, ya quedo la actualización, así ya estaré enterado de todo lo que pasa con mi cuenta de Afore.",
  5 * 24
);

node5_3.quotes = [
  "“Se tardaron en actualizar mis datos, 5 dias hábiles, se me hace demasiado sólo para cambiar un correo”",
];

const node6 = new TreeNode(2, false, -4200, 2710);

node6.isLast = true;
node6.ending = 0;

node1.descendants = [
  {
    node: node1_1,
    hint: "Le preguntaré a mis amigos si ellos saben algo del tema",
  },
  {
    node: node1_2,
    hint: "Buscaré un poco más del tema en internet",
  },
];

node1_1.descendants = [
  {
    node: node1_2,
    hint: "Continuar",
  },
];

//node1_1.game = 8;

node1_2.descendants = [
  {
    node: node2,
    hint: "Continuar",
  },
];

node2.descendants = [
  {
    node: node2_1,
    hint: "Bajaré la app y ahí revisaré mi pension",
  },
  {
    node: node2_2,
    hint: "Revisaré en su página primero",
  },
];

node2_1.descendants = [
  {
    node: node2_1_1,
    hint: "Investigaré primero",
  },
  {
    node: node3,
    hint: "Seguro es para mejorar la pensión, haré la aportación",
  },
];

node2_1_1.descendants = [
  {
    node: node3,
    hint: "Continuar",
  },
];

node2_2.descendants = [
  {
    node: node2_2_1,
    hint: "Continuar",
  },
];

node2_2_1.descendants = [
  {
    node: node3,
    hint: "Bajaré la app y veré cómo hacerlas por ahí",
  },
  {
    node: node2_2_1_1,
    hint: "Marcaré al teléfono de Profuturo a ver si ellos me pueden asesorar sobre el tema",
  },
];

node2_2_1_1.descendants = [
  {
    node: node2_2_1_1_1,
    hint: "Continuar",
  },
];

node2_2_1_1_1.descendants = [
  {
    node: node2_2_1_1_2,
    hint: "Continuar",
  },
];

node2_2_1_1_2.descendants = [
  {
    node: node2_2_1_1_3,
    hint: "Continuar",
  },
];

node2_2_1_1_3.descendants = [
  {
    node: node2_2_1_1_4,
    hint: "Continuar",
  },
];

node2_2_1_1_4.descendants = [
  {
    node: node2_2_1_1_5,
    hint: "Continuar",
  },
];

node2_2_1_1_5.descendants = [
  {
    node: node2_2_1_2,
    hint: "Continuar",
  },
];

node2_2_1_2.descendants = [
  {
    node: node3,
    hint: "Continuar",
  },
];

node3.descendants = [
  {
    node: node3_1,
    hint: "Continuar",
  },
];

node3_1.descendants = [
  {
    node: node3_2,
    hint: "Continuar",
  },
];

node3_2.descendants = [
  {
    node: node3_2_1,
    hint: "$200",
  },
  {
    node: node3_2_2,
    hint: "$500",
  },
  {
    node: node3_2_3,
    hint: "$1000",
  },
];

node3_2_1.descendants = [
  {
    node: node3_3,
    hint: "Continuar",
  },
];

node3_2_2.descendants = [
  {
    node: node3_3,
    hint: "Continuar",
  },
];

node3_2_3.descendants = [
  {
    node: node3_3,
    hint: "Continuar",
  },
];

node3_3.descendants = [
  {
    node: node3_4,
    hint: "Continuar",
  },
];

node3_4.descendants = [
  {
    node: node4,
    hint: "Continuar",
  },
];

node4.descendants = [
  {
    node: node4_1,
    hint: "Continuar",
  },
];

node4_1.descendants = [
  {
    node: node4_1_1,
    hint: "Marcaré a Profuturo para aclarar este tema",
  },
  {
    node: node4_3,
    hint: "Supongo si es del trámite de aportaciones esperaré a ver qué pasa",
  },
];

node4_1_1.descendants = [
  {
    node: node4_1_1_1,
    hint: "Continuar",
  },
];

node4_1_1_1.descendants = [
  {
    node: node4_1_1_2,
    hint: "Continuar",
  },
];

node4_1_1_2.descendants = [
  {
    node: node4_1_1_3,
    hint: "Continuar",
  },
];

node4_1_1_3.descendants = [
  {
    node: node4_1_1_4,
    hint: "Continuar",
  },
];

node4_1_1_4.descendants = [
  {
    node: node4_2,
    hint: "Continuar",
  },
];

node4_2.descendants = [
  {
    node: node4_3,
    hint: "Continuar",
  },
];

node4_3.descendants = [
  {
    node: node4_4,
    hint: "Continuar",
  },
];

node4_4.descendants = [
  {
    node: node4_6,
    hint: "Continuar",
  },
];

node4_6.descendants = [
  {
    node: node4_7,
    hint: "Continuar",
  },
];

node4_7.descendants = [
  {
    node: node5,
    hint: "Continuar",
  },
];

node5.descendants = [
  {
    node: node5_1,
    hint: "Continuar",
  },
];

node5_1.descendants = [
  {
    node: node5_1_1,
    hint: "Continuar",
  },
];

node5_1_1.descendants = [
  {
    node: node5_2,
    hint: "Continuar",
  },
];

node5_2.descendants = [
  {
    node: node5_3,
    hint: "Continuar",
  },
];

node5_3.descendants = [
  {
    node: node6,
    hint: "Continuar",
  },
];

export default node1;
